import React, { useState, useContext } from 'react'
import Control from 'react-leaflet-control'

import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import AddLocation from '@material-ui/icons/AddLocation'
import AccountTree from '@material-ui/icons/AccountTree'
import DashboardIcon from '@material-ui/icons/Dashboard'
import GroupIcon from '@material-ui/icons/Group'
import ExitToApp from '@material-ui/icons/ExitToApp'

import { AppContext } from '../contexts/app-context'

import PlaceAdd from './place-add'
import Projects from './projects'
import Dashboard from './dashboard'
import Users from './users'
import Exit from './exit'

const HomeMenu = ({ position }) => {
  const [openAdd, setOpenAdd] = useState(false)
  const [openProjects, setOpenProjects] = useState(false)
  const [openDashboard, setOpenDashboard] = useState(false)
  const [openUsers, setOpenUsers] = useState(false)
  const [openExit, setOpenExit] = useState(false)

  const { currentUser, isAdmin } = useContext(AppContext)

  const add = () => {
    setAnchorEl(null)
    setOpenAdd(true)
  }

  const projects = () => {
    setAnchorEl(null)
    setOpenProjects(true)
  }

  const dashboard = () => {
    setAnchorEl(null)
    setOpenDashboard(true)
  }

  const users = () => {
    setAnchorEl(null)
    setOpenUsers(true)
  }

  const exit = () => {
    setAnchorEl(null)
    setOpenExit(true)
  }

  const closeAdd = () => {
    setAnchorEl(null)
    setOpenAdd(false)
  }

  const closeProjects = () => {
    setAnchorEl(null)
    setOpenProjects(false)
  }

  const closeDashboard = () => {
    setAnchorEl(null)
    setOpenDashboard(false)
  }

  const closeUsers = () => {
    setAnchorEl(null)
    setOpenUsers(false)
  }

  const closeExit = () => {
    setAnchorEl(null)
    setOpenExit(false)
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Control position={position} >
      <Tooltip title="Menu" placement="right" arrow>
        <Fab color="secondary" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </Fab>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeAdd}
        keepMounted
      >
        <Box m={2} display="flex" alignItems="center">
          <Avatar alt={currentUser.name} />
          <Box m={2}>
            <Typography variant="subtitle1" noWrap>{currentUser.name}</Typography>
            <Typography variant="subtitle2" noWrap>{isAdmin ? 'Administrador' : 'Cliente'}</Typography>
          </Box>
        </Box>
        <Divider></Divider>
        <br />
        {isAdmin && (
          <MenuItem onClick={add}>
            <ListItemIcon>
              <AddLocation />
            </ListItemIcon>
            <Typography noWrap>
              Adicionar Imóvel
            </Typography>
          </MenuItem>
        )}
        <MenuItem onClick={projects}>
          <ListItemIcon>
            <AccountTree />
          </ListItemIcon>
          <Typography noWrap>
            Meus Projetos
          </Typography>
        </MenuItem>
        {isAdmin && (
          <MenuItem onClick={dashboard}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <Typography noWrap>
              Dashboard
            </Typography>
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem onClick={users}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <Typography noWrap>
              Usuários
            </Typography>
          </MenuItem>
        )}
        <br />
        <Divider></Divider>
        <br />
        <MenuItem onClick={exit}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <Typography noWrap>
            Sair
          </Typography>
        </MenuItem>
      </Menu>
      {isAdmin && (<PlaceAdd isOpen={openAdd} close={closeAdd}/>)}
      {isAdmin && (<Dashboard isOpen={openDashboard} close={closeDashboard}/>)}
      {isAdmin && (<Users isOpen={openUsers} close={closeUsers}/>)}
      <Projects isOpen={openProjects} close={closeProjects}/>
      <Exit isOpen={openExit} close={closeExit}/>
    </Control>
  )
}

export default HomeMenu
