import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import LinearProgress from '@material-ui/core/LinearProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import Add from '@material-ui/icons/Add'

import { MEMBERS } from '../graphql/member'
import ProjectMemberAdd from './project-member-add'
import ProjectMemberUpdate from './project-member-update'
import ProjectMemberRemove from './project-member-remove'

const ProjectMembers = ({ project, isOpen, close }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isOpenMember, setIsOpenMember] = useState(false)

  const openMember = () => {
    setIsOpenMember(true)
  }

  const closeMember = () => {
    setIsOpenMember(false)
  }

  const { loading, error, data } = useQuery(MEMBERS, {
    variables: {
      projectId: project.id
    }
  })

  if (loading) {
    return (
      <Dialog open={isOpen} onClose={() => onClose()} maxWidth={'xs'} fullWidth>
        <DialogTitle>Membros de {project.name}</DialogTitle>
        <DialogContent>
          <LinearProgress color="secondary" />
        </DialogContent>
      </Dialog>
    )
  }

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  const onClose = () => {
    if (!loading) {
      close()
    }
  }

  return (
    <Dialog open={isOpen} onClose={() => onClose()} maxWidth={'sm'} fullWidth>
        <DialogTitle>Membros de {project.name}</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usuário</TableCell>
              <TableCell style={{width: 110}} align="center">Somente Leitura</TableCell>
              <TableCell style={{width: 50}} align="center">Status</TableCell>
              <TableCell style={{width: 40}} align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.members.map(member => {
              return (
                <TableRow key={member.id}>
                  <TableCell align="left">
                    <Typography noWrap>{member.user.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <ProjectMemberUpdate {...member} />
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      color={'primary'}
                      label={member.status}
                      style={{ letterSpacing: 1 }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ProjectMemberRemove {...member} />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <br/>
        <br/>
        <Tooltip title="Adicionar membro" placement="right" arrow>
          <Fab color="primary" onClick={() => openMember()}>
            {loading ? <CircularProgress color="inherit" /> : <Add/>}
          </Fab>
        </Tooltip>
        <br/>
        <br/>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained" color="primary" disabled={loading}>
          Fechar
        </Button>
      </DialogActions>
      <ProjectMemberAdd isOpen={isOpenMember} close={closeMember} readOnly={true} project={project}  />
    </Dialog>
  )
}

export default ProjectMembers
