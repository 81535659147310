import React, { useState, useEffect } from 'react'
import cep from 'cep-promise'

import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'

const Address = (props) => {
  const classes = useStyles()
  const [cepString, setCep] = useState(props.cep || '')
  const [street, setStreet] = useState(props.street || '')
  const [number, setNumber] = useState(props.number || '')
  const [complement, setComplement] = useState(props.complement || '')
  const [neighborhood, setNeighborhood] = useState(props.neighborhood || '')
  const [city, setCity] = useState(props.city || '')
  const [state, setState] = useState(props.state || '')
  const [expanded, setExpanded] = useState('')

  const expand = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const { setAddress } = props

  useEffect(() => {
    if(setAddress) {
      setAddress({
        cep: cepString,
        street,
        number,
        complement,
        neighborhood,
        city,
        state
      })
    }
  }, [cepString, street, number, complement, neighborhood, city, state, setAddress])

  async function address() {
    try {
      const result = await cep(cepString)
      setStreet(result.street)
      setNeighborhood(result.neighborhood)
      setCity(result.city)
      setState(result.state)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ExpansionPanel style={{ margin: 10 }} expanded={expanded === 'address'} onChange={expand('address')}>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <Typography>{props.panelTitle}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.root}>
        <TextField
          label="CEP"
          variant="outlined"
          value={cepString}
          onChange={e => setCep(e.target.value)}
          onBlur={() => address()}
          style={{ width: '27%' }}
          InputLabelProps={{ shrink: true }}
          InputProps={{ inputComponent: Cep }}
        />
        <TextField
          label="Rua"
          variant="outlined"
          onChange={e => setStreet(e.target.value)}
          value={street}
          style={{ width: '65%' }}
          InputLabelProps={{ shrink: true }}
          InputProps={{ inputProps: { tabIndex: -1 } }}
        />
        <TextField
          label="Número"
          variant="outlined"
          onChange={e => setNumber(e.target.value)}
          value={number}
          style={{ width: '27%' }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Complemento"
          variant="outlined"
          onChange={e => setComplement(e.target.value)}
          value={complement}
          style={{ width: '65%' }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Bairro"
          variant="outlined"
          onChange={e => setNeighborhood(e.target.value)}
          value={neighborhood}
          style={{ width: '95.5%' }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Cidade"
          variant="outlined"
          onChange={e => setCity(e.target.value)}
          value={city}
          style={{ width: '65%' }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Estado"
          variant="outlined"
          onChange={e => setState(e.target.value)}
          value={state}
          style={{ width: '27%' }}
          InputLabelProps={{ shrink: true }}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

function Cep(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="#####-###"
      isNumericString
    />
  )
}

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiExpansionPanelDetails)

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}))

export default Address
