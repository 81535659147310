import React, { useState, useContext } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import EmojiFlags from '@material-ui/icons/EmojiFlags'
import Control from 'react-leaflet-control'

import { AppContext } from '../contexts/app-context'
import PlaceStatus from './place-status'

const HomeFilters = ({ position, places }) => {
  const [label, setLabel] = useState(false)
  const { isAdmin } = useContext(AppContext)

  return (
    <Control position={position}>
      {label && (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: 230,
          height: isAdmin ? 170 : 140,
          padding: 5,
          backgroundColor: '#fff',
          marginBottom: 5,
          borderRadius: 5
        }}>
          <PlaceStatus size="small" status="ok" hasClick={true}/>
          <PlaceStatus size="small" status="geo" hasClick={true}/>
          <PlaceStatus size="small" status="contract" hasClick={true}/>
          <PlaceStatus size="small" status="geo2" hasClick={true}/>
          <PlaceStatus size="small" status="empty" hasClick={true}/>
          {isAdmin && <PlaceStatus size="small" status="blocked" hasClick={true}/>}
        </div>
      )}
      <IconButton size="small" style={{ backgroundColor: '#fff', color: '#000', boxShadow: '0 1px 5px rgba(0,0,0,0.65)', borderRadius: 5, marginBottom: 20 }} onClick={e => setLabel(!label)}>
        {label ? <Close size="small" fontSize="small" /> : <EmojiFlags size="small" fontSize="small" />}
      </IconButton>
    </Control>
  )
}

export default HomeFilters
