import React, { useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { PlacesContext } from '../contexts/places-context'
import { PLACES, REMOVE_PLACE } from '../graphql/place'

const PlaceRemove = ({ id, isOpen, close }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [removePlace, { loading }] = useMutation(
    REMOVE_PLACE,
    {
      update(cache, { data: { removePlace } }) {
        enqueueSnackbar(`O imóvel foi removido com sucesso.`, { variant: 'success' })
        close()
        setShowDetais(false)
      },
      refetchQueries: [{ query: PLACES }]
    }
  )

  const { setShowDetais } = useContext(PlacesContext)

  const remove = (id) => {
    try {
      removePlace({
        variables: {
          id
        }
      })
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const onClose = () => {
    if (!loading) {
      close()
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Remover Imóvel</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tem certeza que deseja remover este imóvel?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={() => remove(id)} disabled={loading}>
          Remover
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PlaceRemove
