import React, { useRef, useState, useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloudUpload from '@material-ui/icons/CloudUpload'
import Add from '@material-ui/icons/Add'

import ProjectSelect from './project-select'
import ProjectAdd from './project-add'
import { PLACES, ADD_PLACE } from '../graphql/place'
import { PlacesContext } from '../contexts/places-context'

const PlaceAdd = ({ isOpen, close }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { setLatlng } = useContext(PlacesContext)
  const inputFile = useRef(null)
  const inputText = useRef(null)
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [projectId, setProjectId] = useState('select')
  const [isOpenAssProject, setIsOpenAddProject] = useState(false)
  const [geometry, setGeometry] = useState(null)
  const [fileName, setFileName] = useState('')
  const [addPlace, { loading }] = useMutation(
    ADD_PLACE,
    {
      update(cache, { data: { addPlace } }) {
        setLatlng([addPlace.project.lat, addPlace.project.lng])
        enqueueSnackbar(`O imóvel foi cadastrado com sucesso.`, { variant: 'success' })
      },
      refetchQueries: [{ query: PLACES }]
    }
  )

  const open = (e) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }

    const reader = new FileReader()
    reader.onload = async (e) => {
      try {
        const result = JSON.parse(e.target.result)
        setCode(result.name)
        setGeometry(result.features[0].geometry)
        inputText.current.focus()
      } catch (error) {
        console.log(error)
        enqueueSnackbar(`Não foi possível carregar este arquivo.`, { variant: 'error' })
      }
    }
    reader.readAsText(files[0])
    setFileName(files[0].name)
  }

  const submit = async (e) => {
    e.preventDefault()

    try {
      await addPlace({
        variables: {
          code,
          name,
          projectId,
          geometry
        }
      })
      clear()
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const clear = () => {
    if (!loading) {
      setCode('')
      setName('')
      setProjectId('select')
      setGeometry(null)
      setFileName('')

      close()
    }
  }

  const select = (e) => {
    setProjectId(e.target.value)
  }

  const addProject = () => {
    setIsOpenAddProject(true)
  }

  const closeAddProject = () => {
    setIsOpenAddProject(false)
  }

  return (
    <Dialog open={isOpen} onClose={() => clear()} maxWidth={'xs'} fullWidth>
      <DialogTitle>Adicionar Imóvel</DialogTitle>
      <form onSubmit={submit}>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextField
              label="GeoJSON"
              variant="outlined"
              value={fileName}
              onClick={() => inputFile.current.click()}
              InputLabelProps={{ shrink: true }}
              style={{ width: '85%' }}
              autoFocus
              required
            />
            <Tooltip title="Selecionar Arquivo" arrow>
              <Fab size="small" color="primary" onClick={() => inputFile.current.click()}>
                <CloudUpload />
              </Fab>
            </Tooltip>
            <input id='document' accept=".geojson" ref={inputFile} style={{ display: 'none' }} type="file" onChange={open} />
          </div>
          <br />
          <TextField
            label="Código do imóvel"
            variant="outlined"
            value={code}
            onChange={e => setCode(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
          />
          <br />
          <br />
          <TextField
            label="Nome do imóvel"
            variant="outlined"
            inputRef={inputText}
            value={name}
            onChange={e => setName(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
          />
          <br />
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ProjectSelect projectId={projectId} select={select} width={'85%'} />
            <Tooltip title="Adicionar Projeto" arrow>
              <Fab size="small" color="primary" onClick={() => addProject()}>
                <Add />
              </Fab>
            </Tooltip>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => clear()} color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            Adicionar
          </Button>
        </DialogActions>
      </form>
      <ProjectAdd isOpen={isOpenAssProject} close={closeAddProject}/>
    </Dialog>
  )
}

export default PlaceAdd
