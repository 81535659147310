import React, { Fragment, useRef, useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import CloudUpload from '@material-ui/icons/CloudUpload'
import { makeStyles } from '@material-ui/core/styles'

import { PLACE } from '../graphql/place'
import { DOCUMENTS, ADD_DOCUMENT, UPDATE_DOCUMENT } from '../graphql/document'

const PlaceDocument = ({ document, isOpen, close, placeId, readOnly }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const inputFile = useRef(null)
  const [file, setFile] = useState({ name: '' })
  const [type, setType] = useState('registration')
  const [thirdParties, setThirdParties] = useState(false)
  const [final, setFinal] = useState(false)
  const [endorsed, setEndorsed] = useState(false)
  const [issuedAt, setIssuedAt] = useState('')
  const [signedAt, setSignedAt] = useState('')
  const [endorsedAt, setEndorsedAt] = useState('')
  const [validUntil, setValidUntil] = useState('')

  const [addDocument, add] = useMutation(
    ADD_DOCUMENT,
    {
      update() {
        enqueueSnackbar(`O documento foi cadastrado com sucesso.`, { variant: 'success' })
      },
      refetchQueries: [{ query: DOCUMENTS, variables: { placeId } }, { query: PLACE, variables: { id: placeId } }]
    }
  )

  const [updateDocument, update] = useMutation(
    UPDATE_DOCUMENT,
    {
      update() {
        enqueueSnackbar(`O documento foi atualizado com sucesso.`, { variant: 'success' })
      },
      refetchQueries: [{ query: DOCUMENTS, variables: { placeId } }, { query: PLACE, variables: { id: placeId } }]
    }
  )

  useEffect(() => {
    if (document) {
      setFile({ name: document.filename })
      setType(document.type)
      setThirdParties(document.thirdParties)
      setFinal(document.final)
      setEndorsed(document.endorsed)
      setIssuedAt(document.issuedAt)
      setSignedAt(document.signedAt)
      setEndorsedAt(document.endorsedAt)
      setValidUntil(document.validUntil)
    }
  }, [document, isOpen])

  const open = ({ target: { validity, files: [file] } }) => {
    if (validity.valid) {
      setFile(file)
    }
  }

  const select = (type) => {
    setType(type)
  }

  const submit = async (e) => {
    e.preventDefault()

    try {
      if (document) {
        await updateDocument({
          variables: {
            id: document.id,
            type,
            thirdParties,
            endorsed,
            final,
            issuedAt,
            signedAt,
            endorsedAt,
            validUntil
          }
        })
      } else {
        await addDocument({
          variables: {
            file,
            placeId,
            type,
            thirdParties,
            endorsed,
            final,
            issuedAt,
            signedAt,
            endorsedAt,
            validUntil
          }
        })
      }

      onClose()
    } catch(error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const onClose = () => {
    // if (!loading) {
      setFile({ name: '' })
      setType('registration')
      setThirdParties(false)
      setEndorsed(false)
      setIssuedAt('')
      setValidUntil('')
      setEndorsedAt('')
      setSignedAt('')

      close()

    // }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={'xs'}>
      <DialogTitle>Dados do Documento</DialogTitle>
      <form onSubmit={submit} encType={'multipart/form-data'}>
        <DialogContent className={classes.root}>
          <TextField
            label="Documento"
            placeholder="Clique para selecionar o documento..."
            variant="outlined"
            value={file.name}
            onClick={() => !document ? inputFile.current.click() : null}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" color="primary" disabled={Boolean(document)}>
                    <CloudUpload />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ width: 380 }}
            disabled={Boolean(document)}
            required
          />
          {!document && (<input id='document' accept="*" ref={inputFile} style={{ display: 'none' }} type="file" onChange={open} />)}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Tipo de Documento</InputLabel>
            <Select
              value={type}
              onChange={e => select(e.target.value)}
              label="Tipo de Documento"
            >
              <MenuItem value={'registration'}>Matrícula</MenuItem>
              <MenuItem value={'contract'}>Contrato</MenuItem>
              <MenuItem value={'geo'}>GEO</MenuItem>
              <MenuItem value={'car'}>CAR</MenuItem>
              <MenuItem value={'ccir'}>CCIR</MenuItem>
              <MenuItem value={'itr'}>ITR</MenuItem>
              <MenuItem value={'other'}>Outro</MenuItem>
            </Select>
          </FormControl>
          {type === 'contract' && (
            <Fragment>
              <TextField
                label="Data da Assinatura"
                variant="outlined"
                type="date"
                defaultValue={signedAt}
                onChange={e => setSignedAt(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Data de Validade"
                variant="outlined"
                type="date"
                defaultValue={validUntil}
                onChange={e => setValidUntil(e.target.value)}
                InputLabelProps={{ shrink: true }}
                required
              />
              <TextField
                label="Data da Averbação"
                variant="outlined"
                type="date"
                defaultValue={endorsedAt}
                onChange={e => setEndorsedAt(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={thirdParties}
                    onChange={e => setThirdParties(e.target.checked)}
                  />
                }
                label="Contrato com terceiros"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={final}
                    onChange={e => setFinal(e.target.checked)}
                  />
                }
                label="Versão final"
              />
            </Fragment>
          )}
          {type === 'geo' && (
            <FormControlLabel
              control={
                <Switch
                  checked={endorsed}
                  onChange={e => setEndorsed(e.target.checked)}
                />
              }
              label="Averbado"
            />
          )}
          {(type === 'registration' || type === 'ccir' || type === 'itr') && (
            <TextField
              label="Data da Emissão"
              variant="outlined"
              type="date"
              defaultValue={issuedAt}
              onChange={e => setIssuedAt(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            {readOnly ? 'Fechar' : 'Cancelar'}
          </Button>
          {!readOnly && (
            <Button type="submit" variant="contained" color="primary" disabled={(add && add.loading) || (update && update.loading)}>
              {document ? 'Salvar' : 'Cadastrar'}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 180,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
}))

export default PlaceDocument
