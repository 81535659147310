import React from 'react'

import { LayersControl } from 'react-leaflet'
import { GoogleLayer } from 'react-leaflet-google-v2'

const HomeLayers = ({ position }) => {
  const key = 'AIzaSyBTLtwPelqw5mrIOyqcp6SyT_LSXy5elBM'
  const { BaseLayer } = LayersControl

  return (
    <LayersControl position={position} collapsed>
      <BaseLayer name="Terreno">
        <GoogleLayer googlekey={key} maptype={'TERRAIN'} />
      </BaseLayer>
      <BaseLayer name="Satélite">
        <GoogleLayer googlekey={key} maptype={'SATELLITE'} />
      </BaseLayer>
      <BaseLayer name="Híbrido" checked>
        <GoogleLayer googlekey={key} maptype={'HYBRID'} libraries={['geometry', 'places']} checked />
      </BaseLayer>
    </LayersControl>
  )
}

export default HomeLayers
