import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/react-hooks'
import { Grid, CircularProgress, Typography, withStyles, Button, TextField } from '@material-ui/core'

import logo from '../assets/images/renobrax.jpeg'
import { RESET } from '../graphql/user'

const Reset = ({ classes, match }) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')

  const [reset, { loading } ] = useMutation(RESET, {
    update() {
      enqueueSnackbar(`Senha alterada com sucesso.`, { variant: 'success' })
      history.push('/')
    }
  })

  const submit = async (e) => {
    e.preventDefault()

    try {
      await reset({
        variables: {
          token: match.params.token,
          newPassword,
          repeatNewPassword
        }
      })
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="Renobrax" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Typography variant="button" style={{ fontSize: 18 }} color="primary">
            Cadastre sua senha
          </Typography>
          <br />
          <form onSubmit={submit}>
            <TextField
              id="newPassword"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              margin="normal"
              placeholder="Senha"
              type="password"
              autoFocus
              fullWidth
              required
            />
            <TextField
              id="repeatNewPassword"
              value={repeatNewPassword}
              onChange={e => setRepeatNewPassword(e.target.value)}
              margin="normal"
              placeholder="Repetir Senha"
              type="password"
              fullWidth
              required
            />
            <div className={classes.formButtons}>
              {loading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button type="submit" variant="contained" color="primary" size="large">
                  Cadastrar Senha
                </Button>
              )}
            </div>
          </form>
        </div>
        <Typography color="secondary" className={classes.copyright}>
          © 2020 - Renobrax
        </Typography>
      </div>
    </Grid>
  )
}

const styles = theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%"
    },
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  logotypeImage: {
    width: 600,
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%"
    }
  },
  form: {
    width: 320
  },
  tab: {
    fontWeight: 400,
    fontSize: 18
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    }
  }
})

export default withStyles(styles)(Reset)
