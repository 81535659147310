import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/react-hooks'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Grid, CircularProgress, Typography, withStyles, Button, TextField } from '@material-ui/core'

import logo from '../assets/images/renobrax.jpeg'
import Forgot from './forgot'
import { AppContext } from '../contexts/app-context'
import { SIGN_IN } from '../graphql/user'

const SignIn = ({ classes }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const [signIn, { loading } ] = useMutation(SIGN_IN)

  const { setToken } = useContext(AppContext)

  const submit = async (e) => {
    e.preventDefault()

    if (!executeRecaptcha) {
      enqueueSnackbar('Você é um robô? 🤔', { variant: 'error' })
      return
    }

    const recaptchaToken = await executeRecaptcha('signin')
    if (!recaptchaToken) {
      enqueueSnackbar('Você é um robô? 🤔', { variant: 'error' })
      return
    }

    try {
      const result = await signIn({
        variables: {
          recaptchaToken,
          email,
          password
        }
      })
      setToken(result.data.signIn)
      history.push('/')
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="Renobrax" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <form onSubmit={submit}>
            <TextField
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              margin="normal"
              placeholder="E-mail"
              type="email"
              fullWidth
              autoFocus
              required
            />
            <TextField
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              margin="normal"
              placeholder="Senha"
              type="password"
              fullWidth
              required
            />
            <div className={classes.formButtons}>
              {loading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button type="submit" variant="contained" color="primary" size="large">
                  Entrar
                </Button>
              )}
              <Button color="secondary" size="large" className={classes.forgetButton} onClick={open}>
                Esqueceu a senha?
              </Button>
            </div>
          </form>
          <Forgot isOpen={isOpen} close={close} />
        </div>
        <Typography color="secondary" className={classes.copyright}>
          © 2020 - Renobrax
        </Typography>
      </div>
    </Grid>
  )
}

const styles = theme => ({
  container: {
    height: "98vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%"
    },
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  logotypeImage: {
    width: 600,
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%"
    }
  },
  form: {
    width: 300
  },
  tab: {
    fontWeight: 400,
    fontSize: 18
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400
  },
  copyright: {
    // marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    }
  }
})

export default withStyles(styles)(SignIn)
