import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { LAYOUTS, ACTIVE_LAYOUTS, UPDATE_LAYOUT } from '../graphql/layout'

const ProjectLayoutUpdate = ({ id, enabled, project }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [ updateLayout, { loading, error } ] = useMutation(UPDATE_LAYOUT, {
    update() {
      enqueueSnackbar(`Layout atualizado com sucesso.`, { variant: 'success' })
    },
    refetchQueries: [{ query: LAYOUTS, variables: { projectId: project.id } }, { query: ACTIVE_LAYOUTS }]
  })

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  const update = async () => {
    try {
      await updateLayout({
        variables: {
          id,
          enabled: !enabled
        }
      })
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <Tooltip title={enabled ? 'Esconder' : 'Visualizar'} arrow>
      <Fab size="small" color="secondary" onClick={update}>
        {enabled
        ? loading ?  <CircularProgress color="inherit" /> : <Visibility />
        : loading ?  <CircularProgress color="inherit" /> : <VisibilityOff />}
      </Fab>
    </Tooltip>
  )
}

export default ProjectLayoutUpdate
