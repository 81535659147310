import React from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Error from '@material-ui/icons/Error'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const PlaceChecklist = ({ checklist }) => {
  const classes = useStyles()

  const checked = checklist ? checklist.filter(item => !item.error) : []
  const progress = (checked.length * 100) / 6

  return (
    <TableContainer>
      <LinearProgress variant="determinate" color="secondary" value={progress} />
      <br />
      <Table size="small" className={classes.table}>
        <TableBody>
          {checklist && checklist.map(item => {
            return (
              <StyledTableRow key={item.type}>
                <TableCell width={10} align="left">
                  {item.error ? <Error color="error" /> : <CheckCircle style={{ color: '#4caf50' }} />}
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2" noWrap>{item.message}</Typography>
                </TableCell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:last-child td': {
      borderBottom: 0,
    },
  },
}))(TableRow)

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed'
  },
}))

export default PlaceChecklist