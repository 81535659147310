import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { AppContext } from '../contexts/app-context'

const Exit = ({ isOpen, close }) => {
  const history = useHistory()
  const { removeToken } = useContext(AppContext)

  const exit = () => {
    removeToken('renobrax-token')
    history.push('/')
    window.location.reload(true)
  }

  return (
    <Dialog open={isOpen} onClose={close} fullWidth>
      <DialogTitle>Sair do Sistema</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tem certeza que deseja sair do sistema?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={exit}>
          Sair
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Exit
