import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

import { USERS } from '../graphql/user'

const UserSelect = ({ userId, select }) => {
  const { loading, error, data } = useQuery(USERS)

  if (error) {
    console.log(error)
  }

  return (
    <FormControl variant="outlined" style={{ width: '100%' }} required>
      <InputLabel>Usuário</InputLabel>
      <Select
        value={userId || 'select'}
        onChange={select}
        label="Usuário"
      >
        <MenuItem value="select">{loading ? 'Carregando usuários...' : 'Clique para selecionar o usuário'}</MenuItem>
        {data && data.users.map(user => (
          <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default UserSelect
