import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'

import UserSelect from './user-select'
import { MEMBERS, ADD_MEMBER } from '../graphql/member'

const ProjectMemberAdd = ({ project, isOpen, close }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [userId, setUserId] = useState('')
  const [validUntil, setValidUntil] = useState('')
  const [readOnly, setReadOnly] = useState(true)

  const [addMember, { loading }] = useMutation(
    ADD_MEMBER,
    {
      update(cache, { data: { addMember } }) {
        enqueueSnackbar('O membro foi adicionado com sucesso.', { variant: 'success' })
        onClose()
      },
      refetchQueries: [{ query: MEMBERS, variables: { projectId: project.id } }]
    }
  )

  const submit = async (e) => {
    e.preventDefault()

    if (!userId) {
      enqueueSnackbar('Você precisa selecionar um usuário.', { variant: 'error' })
      return
    }

    try {

      await addMember({
        variables: {
          projectId: project.id,
          userId,
          validUntil,
          readOnly
        }
      })
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const select = (e) => {
    setUserId(e.target.value)
  }

  const onClose = () => {
    if (!loading) {
      setUserId('')

      close()
    }
  }

  return (
    <Dialog open={isOpen} onClose={() => onClose()} maxWidth={'xs'} fullWidth>
      <DialogTitle>
        Adicionar Membro
      </DialogTitle>
      <form onSubmit={submit}>
        <DialogContent>
          <UserSelect userId={userId} select={select} />
          <br /><br />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <TextField
              label="Data de Expiração"
              variant="outlined"
              type="date"
              defaultValue={validUntil}
              onChange={e => setValidUntil(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={readOnly}
                  onChange={e => setReadOnly(e.target.checked)}
                />
              }
              label="Somente leitura"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            Adicionar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ProjectMemberAdd
