import React, { Fragment, useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import NumberFormat from 'react-number-format'

import { PLACE } from '../graphql/place'
import { ADD_OWNER, UPDATE_OWNER } from '../graphql/owner'
import Address from './address'

const PlaceOwner = ({ owner, search, placeId, isOpen, close, readOnly }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [name, setName] = useState('')
  const [isCompany, setIsCompany] = useState(false)
  const [documentId, setDocumentId] = useState('')
  const [rg, setRg] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [occupation, setOccupation] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [registration, setRegistration] = useState('')
  const [notes, setNotes] = useState('')
  const [address, setAddress] = useState({})

  const [addOwner, add] = useMutation(
    ADD_OWNER,
    {
      update() {
        enqueueSnackbar(`O proprietário foi cadastrado com sucesso.`, { variant: 'success' })
      },
      refetchQueries: [{ query: PLACE, variables: { id: placeId } }]
    }
  )

  const [updateOwner, update] = useMutation(
    UPDATE_OWNER,
    {
      update() {
        enqueueSnackbar(`O proprietário foi salvo com sucesso.`, { variant: 'success' })
      },
      refetchQueries: [{ query: PLACE, variables: { id: placeId } }]
    }
  )

  useEffect(() => {
    if (owner) {
      setDocumentId(owner.documentId)
      setName(owner.name)
      setIsCompany(owner.isCompany)
      setRg(owner.rg)
      setEmail(owner.email)
      setPhone(owner.phone)
      setOccupation(owner.occupation)
      setDateOfBirth(owner.dateOfBirth)
      setRegistration(owner.registration)
      setNotes(owner.notes)
      setAddress(owner.address)
    } else if (search) {
      setDocumentId(search.documentId)
      setIsCompany(search.isCompany)
    }
  }, [owner, search, isOpen])

  const submit = async (e) => {
    e.preventDefault()

    try {
      if (owner) {
        await updateOwner({
          variables: {
            id: owner.id,
            placeId,
            name,
            isCompany,
            documentId,
            rg,
            email,
            phone,
            occupation,
            dateOfBirth,
            registration,
            notes,
            address
          }
        })
      } else {
        await addOwner({
          variables: {
            placeId,
            name,
            isCompany,
            documentId,
            rg,
            email,
            phone,
            occupation,
            dateOfBirth,
            registration,
            notes,
            address
          }
        })
      }

      onClose()
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const onClose = () => {
    // if (!(add && add.loading) || !(update && update.loading)) {
      setName('')
      setIsCompany(false)
      setDocumentId('')
      setRg('')
      setEmail('')
      setPhone('')
      setOccupation('')
      setDateOfBirth('')
      setRegistration('')
      setNotes('')
      setAddress(null)

      close()
    // }
  }

  return (
    <Dialog open={isOpen || false} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        Dados do Proprietário
      </DialogTitle>
      <form onSubmit={submit}>
        <DialogContent className={classes.root}>
          <TextField
            label={isCompany ? 'CNPJ' : 'CPF'}
            variant="outlined"
            style={{ width: '47%' }}
            value={documentId}
            onChange={e => setDocumentId(e.target.value)}
            InputProps={{ inputComponent: isCompany ? Cnpj : Cpf }}
            InputLabelProps={{ shrink: true }}
            // disabled={Boolean(owner)}
            required
            autoFocus
          />
          <div style={{ paddingLeft: 10, paddingBottom: 10, display: 'flex', width: '47%' }}>
            <FormControlLabel
              label="Pessoa Jurídica"
              control={
                <Switch
                  tabIndex="-1"
                  disabled={Boolean(owner)}
                  checked={isCompany}
                  onChange={e => setIsCompany(e.target.checked)}
                />
              }
            />
          </div>
          <TextField
            label={isCompany ? 'Razão Social': 'Nome do Proprietário'}
            variant="outlined"
            value={name}
            onChange={e => setName(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
          />
          <TextField
            label="E-mail"
            variant="outlined"
            value={email}
            onChange={e => setEmail(e.target.value)}
            style={{ width: '47%' }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Telefone"
            variant="outlined"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            style={{ width: '47%' }}
            InputProps={{ inputComponent: Phone }}
            InputLabelProps={{ shrink: true }}
          />
          {!isCompany && (
            <Fragment>
              <TextField
                label="Profissão"
                variant="outlined"
                value={occupation}
                onChange={e => setOccupation(e.target.value)}
                style={{ width: '47%' }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="RG"
                variant="outlined"
                value={rg}
                onChange={e => setRg(e.target.value)}
                style={{ width: '47%' }}
                InputProps={{ inputComponent: Rg }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Nro do Registro"
                variant="outlined"
                value={registration}
                onChange={e => setRegistration(e.target.value)}
                style={{ width: '47%' }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Data de Nascimento"
                variant="outlined"
                type="date"
                defaultValue={dateOfBirth}
                onChange={e => setDateOfBirth(e.target.value)}
                style={{ width: '47%' }}
                InputLabelProps={{ shrink: true }}
              />
            </Fragment>
          )}
          <Address panelTitle="Endereço do Proprietário" setAddress={setAddress} {...address} />
          <TextField
            label="Observações"
            multiline
            rows="5"
            value={notes}
            onChange={e => setNotes(e.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {readOnly ? 'Fechar' : 'Cancelar'}
          </Button>
          {!readOnly && (
            <Button type="submit" variant="contained" color="primary" disabled={(add && add.loading) || (update && update.loading)}>
              {owner ? 'Salvar' : 'Cadastrar'}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}

function Cpf(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="###.###.###-##"
      isNumericString
    />
  )
}

function Cnpj(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="##.###.###/####-##"
      isNumericString
    />
  )
}

function Phone(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="(##) #####-####"
      isNumericString
    />
  )
}

function Rg(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="###.###.##-##"
      isNumericString
    />
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}))

export default PlaceOwner
