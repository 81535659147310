import React, { Fragment, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import Delete from '@material-ui/icons/Delete'

import { USERS, REMOVE_USER } from '../graphql/user'

const UserRemove = ({ id, name }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)

  const [ removeMember, { loading, error } ] = useMutation(REMOVE_USER, {
    update() {
      enqueueSnackbar(`Usuário removido com sucesso.`, { variant: 'success' })
    },
    refetchQueries: [{ query: USERS, variables: { id } }]
  })

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  function open() {
    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
  }

  const remove = async () => {
    try {
      await removeMember({
        variables: {
          id
        }
      })
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }

    close()
  }

  return (
    <Fragment>
      <Tooltip title="Remover" arrow>
        <Fab size="small" color="secondary" onClick={open} disabled={loading}>
          {loading ? <CircularProgress color="secondary" /> : <Delete/>}
        </Fab>
      </Tooltip>
      {isOpen && (
        <Dialog open={isOpen} onClose={close} fullWidth>
          <DialogTitle>{`Remover ${name || ''}`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza que deseja remover este usuário?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={remove} disabled={loading}>
              Remover
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  )
}

export default UserRemove
