import React, { useRef } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import LinearProgress from '@material-ui/core/LinearProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import Add from '@material-ui/icons/Add'

import { LAYOUTS, ACTIVE_LAYOUTS, ADD_LAYOUT } from '../graphql/layout'
import ProjectLayoutRemove from './project-layout-remove'
import ProjectLayoutUpdate from './project-layout-update'

const ProjectLayouts = ({ project, isOpen, close }) => {
  const { enqueueSnackbar } = useSnackbar()
  const inputFile = useRef(null)

  const [ addLayout, adding ] = useMutation(ADD_LAYOUT, {
    update() {
      enqueueSnackbar(`Layout adicionado com sucesso.`, { variant: 'success' })
    },
    refetchQueries: [{ query: LAYOUTS, variables: { projectId: project.id } }, { query: ACTIVE_LAYOUTS }]
  })

  const { loading, error, data } = useQuery(LAYOUTS, {
    variables: {
      projectId: project.id
    }
  })

  if (loading) {
    return (
      <Dialog open={isOpen} onClose={() => onClose()} maxWidth={'xs'} fullWidth>
        <DialogTitle>Layouts de {project.name}</DialogTitle>
        <DialogContent>
          <LinearProgress color="secondary" />
        </DialogContent>
      </Dialog>
    )
  }

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  const add = (e) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }

    const reader = new FileReader()
    reader.onload = async (e) => {
      try {
        const { name, features } = JSON.parse(e.target.result)
        await addLayout( {
          variables: {
            name,
            projectId: project.id,
            features
          }
        })
      } catch (error) {
        console.log(error)
        enqueueSnackbar(`Não foi possível carregar este arquivo.`, { variant: 'error' })
      }
    }
    reader.readAsText(files[0])
  }

  const onClose = () => {
    if (!loading || !adding.loading) {
      close()
    }
  }

  return (
    <Dialog open={isOpen} onClose={() => onClose()} maxWidth={'xs'} fullWidth>
      <DialogTitle>Layouts de {project.name}</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Layout</TableCell>
              <TableCell style={{width: 90}} align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.layouts.map(layout => {
              return (
                <TableRow key={layout.id}>
                  <TableCell align="left">
                    <Typography noWrap>{layout.name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <ProjectLayoutUpdate {...layout} />
                      <ProjectLayoutRemove {...layout} />
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <br/>
        <br/>
        <Tooltip title="Adicionar layout" placement="right" arrow>
          <Fab color="primary" onClick={() => inputFile.current.click()} disabled={adding.loading}>
            {adding.loading ? <CircularProgress color="inherit" /> : <Add/>}
          </Fab>
        </Tooltip>
        <input id='document' accept=".geojson" ref={inputFile} style={{ display: 'none' }} type="file" onChange={add} />
        <br/>
        <br/>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained" color="primary" disabled={loading || adding.loading}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectLayouts
