import React, { useContext } from 'react'
import { Map, TileLayer, ZoomControl } from 'react-leaflet'

import HomeMenu from './home-menu'
import HomeLayers from './home-layers'
import HomeSearch from './home-search'
import HomeFilters from './home-filters'
import HomeLogo from './home-logo'
import Places from './places'

import { PlacesContext } from '../contexts/places-context'

const Home = () => {
  const { places, latlng, zoom } = useContext(PlacesContext)

  return (
    <Map className="map" center={latlng} zoom={zoom} zoomControl={false}>
      <ZoomControl position="topleft" style={{ marginBottom: 10 }} />
      <HomeSearch places={places} position="topleft" />
      <HomeMenu position="topright" />
      <HomeFilters position="bottomleft" />
      <HomeLogo position="bottomright" />
      <HomeLayers position="bottomright" />
      <Places />
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        maxZoom={18}
      />
    </Map>
  )
}

export default Home
