import React, { useState, useEffect } from 'react'

import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import PersonAdd from '@material-ui/icons/PersonAdd'
import Edit from '@material-ui/icons/Edit'
import Link from '@material-ui/icons/Link'
import Delete from '@material-ui/icons/Delete'
import Restore from '@material-ui/icons/Restore'
import { makeStyles } from '@material-ui/core/styles'

import PlaceOwner from './place-owner'
import PlaceOwnerSearch from './place-owner-search'
import PlaceOwnersHistory from './place-owners-history'
import PlaceOwnerRemove from './place-owner-remove'

const PlaceOwners = (props) => {
  const classes = useStyles()
  const [owner, setOwner] = useState(null)
  const [owners, setOwners] = useState([])
  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [isOpenHistory, setIsOpenHistory] = useState(false)
  const [isOpenRemove, setIsOpenRemove] = useState(false)

  useEffect(() => {
    if (props.owners) {
      setOwners(props.owners)
    }
  }, [props.owners])

  const openAdd = (owner) => {
    setOwner(owner)
    setIsOpenAdd(true)
  }

  const closeAdd = () => {
    setIsOpenAdd(false)
  }

  const openEdit = (owner) => {
    setOwner(owner)
    setIsOpenEdit(true)
  }

  const closeEdit = () => {
    setIsOpenEdit(false)
  }

  const openHistory = (owner) => {
    setIsOpenHistory(true)
  }

  const closeHistory = () => {
    setIsOpenHistory(false)
  }

  const openRemove = (owner) => {
    setOwner(owner)
    setIsOpenRemove(true)
  }

  const closeRemove = () => {
    setIsOpenRemove(false)
  }

  return (
    <div>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell style={{width: props.readOnly ? 45 : 85}} align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {owners.map(owner => {
              return (
                <TableRow key={owner.id}>
                  <TableCell align="left">
                    <Typography noWrap>{owner.name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Tooltip title={props.readOnly ? 'Visualizar' : 'Editar'} arrow>
                        <Fab size="small" color="secondary" onClick={() => openEdit(owner)}>
                          {props.readOnly ? <Link/> : <Edit />}
                        </Fab>
                      </Tooltip>
                      {!props.readOnly && (
                        <Tooltip title="Remover" arrow>
                          <Fab size="small" color="secondary" onClick={() => openRemove(owner)}>
                            <Delete />
                          </Fab>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <br/>
      {!props.readOnly && (
        <div>
          <Tooltip title="Adicionar Proprietário" placement="bottom" arrow>
            <Fab color="primary" onClick={() => openAdd(null)}>
              <PersonAdd />
            </Fab>
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip title="Histórico de Proprietários" placement="bottom" arrow>
            <Fab color="secondary" onClick={() => openHistory()}>
              <Restore />
            </Fab>
          </Tooltip>
        </div>
      )}
      {isOpenAdd && <PlaceOwnerSearch isOpen={isOpenAdd} close={closeAdd} placeId={props.id} readOnly={props.readOnly}  />}
      {isOpenHistory && <PlaceOwnersHistory isOpen={isOpenHistory} close={closeHistory} placeId={props.id} history={props.ownersHistory}/>}
      {owner && isOpenEdit && <PlaceOwner isOpen={isOpenEdit} close={closeEdit} placeId={props.id} readOnly={props.readOnly} owner={owner}  />}
      {owner && isOpenRemove && <PlaceOwnerRemove isOpen={isOpenRemove} close={closeRemove} placeId={props.id} id={owner.id} />}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed'
  },
}))

export default PlaceOwners