import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { SnackbarProvider } from 'notistack'

import AppContextProvider from '../contexts/app-context'
import PlacesContextProvider from '../contexts/places-context'
import PrivateRoute from '../routes/private-route'
import SignIn from './sign-in'
import Reset from './reset'
import Home from './home'

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          <AppContextProvider>
            <PlacesContextProvider>
              <SnackbarProvider maxSnack={3}>
                <PrivateRoute exact path='/' component={Home} />
                <GoogleReCaptchaProvider reCaptchaKey="6LcR4fgUAAAAAL84HaiLKzbOybK_3VNas5t-q-65">
                  <Route path='/sign-in' component={SignIn} />
                  <Route exact path='/reset/:token' component={Reset} />
                </GoogleReCaptchaProvider>
              </SnackbarProvider>
            </PlacesContextProvider>
          </AppContextProvider>
        </Switch>
      </Router>
    </MuiThemeProvider>
  )
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#253858'
    },
    secondary: {
      main: '#218aa8'
    }
  },
  status: {
    danger: 'red',
  },
})

export default App
