import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { useLeaflet } from 'react-leaflet'
import Control from 'react-leaflet-control'
import L from 'leaflet'

import LinearProgress from '@material-ui/core/LinearProgress'

import { ACTIVE_LAYOUTS } from '../graphql/layout'
import iconUrl from '../assets/images/placemark.png';

const icon = L.icon({
  iconUrl,
  iconRetinaUrl: iconUrl,
  iconSize: [20, 20],
})

export default function ProjectsLayouts({ position }) {
  const { map } = useLeaflet()
  const { enqueueSnackbar } = useSnackbar()
  const [layerGroup, setLayerGroup] = useState(null)

  const { loading, error, data } = useQuery(ACTIVE_LAYOUTS)

  useEffect(() => {
    if (!layerGroup) {
      setLayerGroup(L.layerGroup().addTo(map))
    } else {
      layerGroup.clearLayers()
    }
    if (!loading && data) {
      data.activeLayouts.forEach(({ features }) => {
        features.forEach(({ geometry: { coordinates } }) => {
          L.marker([coordinates[1], coordinates[0]], { icon }).addTo(layerGroup)
        })
      })
    }
  }, [loading, data, layerGroup, map])

  if (loading) {
    return (
      <LinearProgress color="primary" />
    )
  }

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  return (
    <Control>
    </Control>
  )
}
