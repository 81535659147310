import gql from 'graphql-tag'

export const ADD_DOCUMENT = gql`
  mutation AddDocument($file: Upload!, $placeId: String!, $type: DocumentType!, $thirdParties: Boolean, $final: Boolean, $endorsed: Boolean, $issuedAt: String, $signedAt: String, $endorsedAt: String, $validUntil: String) {
    addDocument(file: $file, placeId: $placeId, type: $type, thirdParties: $thirdParties, final: $final, endorsed: $endorsed, issuedAt: $issuedAt, signedAt: $signedAt, endorsedAt: $endorsedAt, validUntil: $validUntil) {
      id
      filename
      type
      thirdParties
      final
      endorsed
      issuedAt
      signedAt
      endorsedAt
      validUntil
      url
      deletedAt
    }
  }
`

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($id: String!, $type: DocumentType!, $thirdParties: Boolean, $final: Boolean, $endorsed: Boolean, $issuedAt: String, $signedAt: String, $endorsedAt: String, $validUntil: String) {
    updateDocument(id: $id, type: $type, thirdParties: $thirdParties, final: $final, endorsed: $endorsed, issuedAt: $issuedAt, signedAt: $signedAt, endorsedAt: $endorsedAt, validUntil: $validUntil) {
      id
      filename
      type
      thirdParties
      final
      endorsed
      issuedAt
      signedAt
      endorsedAt
      validUntil
      url
      deletedAt
    }
  }
`

export const REMOVE_DOCUMENT = gql`
  mutation RemoveDocument($id: String!) {
    removeDocument(id: $id)
  }
`

export const DOCUMENTS_HISTORY = gql`
  query DocumentsHistory($placeId: String!) {
    documentsHistory(placeId: $placeId) {
      id
      filename
      type
      url
      deletedAt
    }
  }
`

export const DOCUMENTS = gql`
  query Documents($placeId: String!) {
    documents(placeId: $placeId) {
      id
      filename
      type
      thirdParties
      final
      endorsed
      issuedAt
      signedAt
      endorsedAt
      validUntil
      url
      deletedAt
    }
  }
`
