import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import NumberFormat from 'react-number-format'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { PROJECTS, ADD_PROJECT, UPDATE_PROJECT } from '../graphql/project'

const ProjectAdd = ({ project, isOpen, close }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [name, setName] = useState('')
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')
  const [addProject, add] = useMutation(
    ADD_PROJECT,
    {
      update(cache, { data: { addProject } }) {
        const { projects } = cache.readQuery({ query: PROJECTS });
        cache.writeQuery({
          query: PROJECTS,
          data: { projects: projects.concat([addProject]) },
        })
        enqueueSnackbar(`O projeto foi cadastrado com sucesso.`, { variant: 'success' })
        onClose()
      }
    }
  )

  const [updateProject, update] = useMutation(
    UPDATE_PROJECT,
    {
      update() {
        enqueueSnackbar(`O projeto foi atualizado com sucesso.`, { variant: 'success' })
        onClose()
      },
    }
  )

  useEffect(() => {
    if (project) {
      setName(project.name)
      setLat(project.lat)
      setLng(project.lng)
    } else {
      setName('')
      setLat('')
      setLng('')
    }
  }, [project, isOpen])

  const submit = async (e) => {
    e.preventDefault()
    try {
      if (project) {
        await updateProject({
          variables: {
            id: project.id,
            name,
            lat: parseFloat(lat),
            lng: parseFloat(lng)
          }
        })
      } else {
        await addProject({
          variables: {
            name,
            lat: parseFloat(lat),
            lng: parseFloat(lng)
          }
        })
      }
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const onClose = () => {
    if (!add.loading || !update.loading) {
      setName('')
      setLat('')
      setLng('')

      close()
    }
  }

  return (
    <Dialog open={isOpen} onClose={() => onClose()} maxWidth={'xs'} fullWidth>
      <DialogTitle>
        {project ? 'Atualizar Projeto' : 'Adicionar Projeto'}
      </DialogTitle>
      <form onSubmit={submit}>
        <DialogContent>
          <TextField
            label="Nome do Projeto"
            variant="outlined"
            value={name}
            onChange={e => setName(e.target.value)}
            InputLabelProps={{ shrink: true }}
            autoFocus
            fullWidth
            required
          />
          <br /><br />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <TextField
              label="Latitude"
              variant="outlined"
              value={lat}
              style={{ width: '48%' }}
              onChange={e => setLat(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputComponent: LatLng }}
              required
            />
            <TextField
              label="Longitude"
              variant="outlined"
              value={lng}
              style={{ width: '48%' }}
              onChange={e => setLng(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputComponent: LatLng }}
              required
            />
          </div>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={add.loading || update.loading}>
            {project ? 'Atualizar' : 'Adicionar'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}


function LatLng(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
    />
  )
}

export default ProjectAdd
