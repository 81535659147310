import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { ApolloProvider } from '@apollo/react-hooks'
import { createUploadLink } from 'apollo-upload-client'

import 'leaflet/dist/leaflet.css'
import './index.css'

import App from './components/app'

const httpLink = createUploadLink({
  uri: '/api',
  credentials: 'same-origin'
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('renobrax-token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
}

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App/>
  </ApolloProvider>,
  document.getElementById('root'),
)
