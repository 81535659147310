import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import { USERS, ADD_USER } from '../graphql/user'

const UserAdd = ({ isOpen, close }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const [addUser, { loading }] = useMutation(
    ADD_USER,
    {
      update(cache, { data: { addUser } }) {
        enqueueSnackbar('O usuário foi adicionado com sucesso.', { variant: 'success' })
        onClose()
      },
      refetchQueries: [{ query: USERS }]
    }
  )

  const submit = async (e) => {
    e.preventDefault()

    try {
      await addUser({
        variables: {
          name,
          email
        }
      })
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const onClose = () => {
    if (!loading) {
      setEmail('')
      close()
    }
  }

  return (
    <Dialog open={isOpen} onClose={() => onClose()} maxWidth={'xs'} fullWidth>
      <DialogTitle>
        Adicionar Usuário
      </DialogTitle>
      <form onSubmit={submit}>
        <DialogContent>
          <TextField
            label="Nome"
            variant="outlined"
            type="name"
            defaultValue={name}
            onChange={e => setName(e.target.value)}
            InputLabelProps={{ shrink: true }}
            autoFocus
            fullWidth
            required
          />
          <br /><br />
          <TextField
            label="E-mail"
            variant="outlined"
            type="email"
            defaultValue={email}
            onChange={e => setEmail(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
          />
        </DialogContent>
        <br/>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            Adicionar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default UserAdd
