import gql from 'graphql-tag'

export const ADD_MEMBER = gql`
  mutation AddMember($projectId: String!, $userId: String!, $validUntil: String, $readOnly: Boolean) {
    addMember(projectId: $projectId, userId: $userId, validUntil: $validUntil, readOnly: $readOnly) {
      id
    }
  }
`

export const UPDATE_MEMBER = gql`
  mutation UpdateMember($id: String!, $readOnly: Boolean!) {
    updateMember(id: $id, readOnly: $readOnly) {
      id
    }
  }
`

export const REMOVE_MEMBER = gql`
  mutation RemoveMember($id: String!) {
    removeMember(id: $id)
  }
`

export const MEMBERS = gql`
  query Members($projectId: String!) {
    members(projectId: $projectId) {
      id
      status
      readOnly
      project {
        id
      }
      user {
        id
        name
      }
    }
  }
`
