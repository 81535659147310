import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import LinearProgress from '@material-ui/core/LinearProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import Add from '@material-ui/icons/Add'

import { USERS } from '../graphql/user'
import UserAdd from './user-add'
import UserRemove from './user-remove'

const Users = ({ project, isOpen, close }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isOpenUser, setIsOpenUser] = useState(false)

  const openUser = () => {
    setIsOpenUser(true)
  }

  const closeUser = () => {
    setIsOpenUser(false)
  }

  const { loading, error, data } = useQuery(USERS)

  if (loading) {
    return (
      <Dialog open={isOpen} onClose={() => onClose()} maxWidth={'xs'} fullWidth>
        <DialogTitle>Usuários do Sistema</DialogTitle>
        <DialogContent>
          <LinearProgress color="secondary" />
        </DialogContent>
      </Dialog>
    )
  }

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  const onClose = () => {
    if (!loading) {
      close()
    }
  }

  return (
    <Dialog open={isOpen} onClose={() => onClose()} maxWidth={'md'} fullWidth>
        <DialogTitle>Usuários do Sistema</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usuário</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell style={{width: 50}} align="center">Status</TableCell>
              <TableCell style={{width: 40}} align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.users.map(user => {
              return (
                <TableRow key={user.id}>
                  <TableCell align="left">
                    <Typography noWrap>{user.name}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography noWrap>{user.email}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      // size={'small'}
                      color={'primary'}
                      label={user.status}
                      style={{ letterSpacing: 1 }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <UserRemove {...user} />
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <br/>
        <br/>
        <Tooltip title="Adicionar usuário" placement="right" arrow>
          <Fab color="primary" onClick={() => openUser()}>
            {loading ? <CircularProgress color="inherit" /> : <Add/>}
          </Fab>
        </Tooltip>
        <br/>
        <br/>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained" color="primary" disabled={loading}>
          Fechar
        </Button>
      </DialogActions>
      <UserAdd isOpen={isOpenUser} close={closeUser} />
    </Dialog>
  )
}

export default Users
