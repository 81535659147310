import React, { Fragment, useState, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import Place from '@material-ui/icons/Place'
import CloudUpload from '@material-ui/icons/CloudUpload'

import { UPDATE_PLACE } from '../graphql/place'

const PlaceGeometryUpdate = ({ id, name, blocked, registration, incra, nirf, car, notes, project, geometry }) => {
  const { enqueueSnackbar } = useSnackbar()
  const inputFile = useRef(null)

  const [isOpen, setIsOpen] = useState(false)
  const [newGeometry, setNewGeometry] = useState(geometry)
  const [fileName, setFileName] = useState('')

  const [updatePlace, { loading }] = useMutation(UPDATE_PLACE)

  const open = (e) => {
    // setProjectId('select')
    setIsOpen(true)
  }

  const close = (e) => {
    setIsOpen(false)
  }

  const select = (e) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }

    const reader = new FileReader()
    reader.onload = async (e) => {
      try {
        const result = JSON.parse(e.target.result)
        setNewGeometry(result.features[0].geometry)
      } catch (error) {
        console.log(error)
        enqueueSnackbar(`Não foi possível carregar este arquivo.`, { variant: 'error' })
      }
    }
    reader.readAsText(files[0])
    setFileName(files[0].name)
  }

  const submit = async (e) => {
    e.preventDefault()

    try {
      await updatePlace({
        variables: {
          id,
          name,
          projectId: project.id,
          blocked,
          registration,
          incra,
          nirf,
          car,
          notes,
          geometry: newGeometry
        }
      })
      enqueueSnackbar(`O imóvel foi atualizado com sucesso.`, { variant: 'success' })
      setIsOpen(false)
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <Fragment>
      <Tooltip title="Alterar polígono" placement="right" arrow>
        <IconButton size="small" color="secondary" onClick={open}>
          <Place/>
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={() => close()} maxWidth={'xs'} fullWidth>
        <DialogTitle>Alterar Polígono</DialogTitle>
        <form onSubmit={submit}>
          <DialogContent>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <TextField
              label="GeoJSON"
              variant="outlined"
              value={fileName}
              onClick={() => inputFile.current.click()}
              InputLabelProps={{ shrink: true }}
              style={{ width: '85%' }}
              required
            />
            <Tooltip title="Selecionar Arquivo" arrow>
              <Fab size="small" color="primary" onClick={() => inputFile.current.click()}>
                <CloudUpload />
              </Fab>
            </Tooltip>
            <input id='document' accept=".geojson" ref={inputFile} style={{ display: 'none' }} type="file" onChange={select} />
          </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => close()} color="primary">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              Atualizar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  )
}

export default PlaceGeometryUpdate
