import React, { createContext, useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'

import useStorage from '../utils/use-storage'

export const AppContext = createContext()

const AppContextProvider = ({ children }) => {
  const [token, setToken, removeToken] = useStorage('renobrax-token')
  const [currentUser, setCurrentUser] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [filters, setFilters] = useState(['geo', 'geo2', 'contract', 'empty', 'ok'])

  useEffect(() => {
    if (token) {
      setCurrentUser(jwtDecode(token))
    }
  }, [token])

  useEffect(() => {
    if (currentUser) {
      let isAdmin = currentUser.type === 'admin'
      setIsAdmin(isAdmin)
      if (isAdmin) {
        setFilters(['geo', 'geo2', 'contract', 'empty', 'ok', 'blocked'])
      }
    }
  }, [currentUser])

  return (
    <AppContext.Provider value={{ token, setToken, removeToken, currentUser, isAdmin, filters, setFilters }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
