import gql from 'graphql-tag'

export const ADD_OWNER = gql`
  mutation AddOwner($name: String!, $placeId: String!, $isCompany: Boolean!, $documentId: String!, $rg: String, $email: String, $phone: String, $occupation: String, $dateOfBirth: String, $registration: String, $notes: String, $address: AddressInput) {
    addOwner(name: $name, placeId: $placeId, isCompany: $isCompany, documentId: $documentId, rg: $rg, email: $email, phone: $phone, occupation: $occupation, dateOfBirth: $dateOfBirth, registration: $registration, notes: $notes, address: $address) {
      id
      documentId
      name
      isCompany
      rg
      email
      phone
      occupation
      dateOfBirth
      registration
      notes
      address {
        cep
        street
        number
        complement
        neighborhood
        city
        state
      }
    }
  }
`

export const UPDATE_OWNER = gql`
  mutation UpdateOwner($id: String!, $name: String!, $placeId: String!, $isCompany: Boolean!, $documentId: String!, $rg: String, $email: String, $phone: String, $occupation: String, $dateOfBirth: String, $registration: String, $notes: String, $address: AddressInput) {
    updateOwner(id: $id, name: $name, placeId: $placeId, isCompany: $isCompany, documentId: $documentId, rg: $rg, email: $email, phone: $phone, occupation: $occupation, dateOfBirth: $dateOfBirth, registration: $registration, notes: $notes, address: $address) {
      id
      documentId
      name
      isCompany
      rg
      email
      phone
      occupation
      dateOfBirth
      registration
      notes
      address {
        cep
        street
        number
        complement
        neighborhood
        city
        state
      }
    }
  }
`

export const REMOVE_OWNER = gql`
  mutation RemoveOwner($id: String!, $placeId: String!) {
    removeOwner(id: $id, placeId: $placeId)
  }
`

export const OWNERS = gql`
  query Owners($placeId: String!) {
    owners(placeId: $placeId) {
      id
      documentId
      name
      isCompany
      rg
      email
      phone
      occupation
      dateOfBirth
      registration
      notes
      address {
        cep
        street
        number
        complement
        neighborhood
        city
        state
      }
    }
  }
`

export const OWNER = gql`
  query Owner($documentId: String!) {
    owner(documentId: $documentId) {
      id
      documentId
      name
      isCompany
      rg
      email
      phone
      occupation
      dateOfBirth
      registration
      notes
      address {
        cep
        street
        number
        complement
        neighborhood
        city
        state
      }
    }
  }
`