import gql from 'graphql-tag'

export const ADD_LAYOUT = gql`
  mutation AddLayout($name: String!, $projectId: String!, $features: [JSON!]) {
    addLayout(name: $name, projectId: $projectId, features: $features) {
      id
    }
  }
`

export const UPDATE_LAYOUT = gql`
  mutation UpdateLayout($id: String!, $enabled: Boolean!) {
    updateLayout(id: $id, enabled: $enabled) {
      id
    }
  }
`

export const REMOVE_LAYOUT = gql`
  mutation RemoveLayout($id: String!) {
    removeLayout(id: $id)
  }
`

export const LAYOUTS = gql`
  query Layouts($projectId: String!) {
    layouts(projectId: $projectId) {
      id
      name
      project {
        id
      }
      features
      enabled
    }
  }
`

export const ACTIVE_LAYOUTS = gql`
  {
    activeLayouts {
      id
      features
      enabled
    }
  }
`
