import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { PLACE } from '../graphql/place'
import { DOCUMENTS, DOCUMENTS_HISTORY, REMOVE_DOCUMENT } from '../graphql/document'

const PlaceDocumentRemove = ({ id, placeId, isOpen, close }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [removeDocument, { loading }] = useMutation(
    REMOVE_DOCUMENT,
    {
      update(cache, { data: { removeDocument } }) {
        const { documents } = cache.readQuery({ query: DOCUMENTS, variables: { placeId } })
        cache.writeQuery({
          query: DOCUMENTS,
          variables: { placeId },
          data: { documents: documents.filter(document => document.id !== id) },
        })
        enqueueSnackbar(`O documento foi removido com sucesso.`, { variant: 'success' })
        close()
      },
      refetchQueries: [{ query: DOCUMENTS_HISTORY, variables: { placeId, existed: true } }, { query: PLACE, variables: { id: placeId } }]
    }
  )

  const remove = (id) => {
    try {
      removeDocument({
        variables: {
          id
        }
      })
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const onClose = () => {
    if (!loading) {
      close()
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Remover Documento</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tem certeza que deseja remover este documento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={() => remove(id)} disabled={loading}>
          Remover
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PlaceDocumentRemove
