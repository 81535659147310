import React, { useState, useEffect } from 'react';
import Control from 'react-leaflet-control'
import { useLeaflet } from 'react-leaflet'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Search from '@material-ui/icons/Search'
import Close from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import L from 'leaflet'

export default function HomeSearch({ position, places }) {
  const classes = useStyles()
  const { map } = useLeaflet()
  const [search, setSearch] = useState(false)
  const [layerGroup, setLayerGroup] = useState(null)
  const [inputValue, setInputChange] = useState('')

  useEffect(() => {
    if (!layerGroup) {
      setLayerGroup(L.layerGroup().addTo(map))
    } else {
      layerGroup.clearLayers()
    }
  }, [layerGroup, setLayerGroup, map, places])

  const options = places && places.features.map((option) => {
    const projectName = option.project.name.toUpperCase()
    return {
      projectName: projectName,
      ...option,
    }
  })

  const filterOptions = createFilterOptions({
    // matchFrom: 'start',
    stringify: (option) => {
      let filterString = option.name

      if (option.registration) {
        filterString += option.registration
      }

      if (option.incra) {
        filterString += option.incra
      }

      if (option.nirf) {
        filterString += option.nirf
      }

      if (option.car) {
        filterString += option.registration
      }

      if (option.project) {
        filterString += option.project.name
      }

      if (option.owners && option.owners.length > 0) {
        filterString += option.owners.reduce((s, o) => s + o.documentId, '')
      }

      if (option.owners && option.owners.length > 0) {
        filterString += option.owners.reduce((s, o) => s + o.name, '')
      }

      if (option.owners && option.owners.length > 0) {
        filterString += option.owners.reduce((s, o) => s + (o.rg || ''), '')
      }

      if (option.owners && option.owners.length > 0) {
        filterString += option.owners.reduce((s, o) => s + (o.email || ''), '')
      }

      return filterString
    }
  })

  const icon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
  })

  const onChange = (event, place) => {
    event.preventDefault()
    layerGroup.clearLayers()

    if (place) {
      const { geometry: { coordinates } } = place
      const { lat, lng } = L.latLngBounds(coordinates).getCenter()
      map.setView([lng, lat], 13)

      L.marker([lng, lat], { icon }).addTo(layerGroup)
    }
  }

  return (
    <Control position={position}>
      <IconButton size="small" style={{ backgroundColor: '#fff', color: '#000', boxShadow: '0 1px 5px rgba(0,0,0,0.65)', borderRadius: 5 }} onClick={e => {
        layerGroup.clearLayers()
        setSearch(!search)
      }}>
        {places ? (search ? <Close size="small" fontSize="small" /> : <Search size="small" fontSize="small" />) : <CircularProgress size={20} className={classes.progress} />}
      </IconButton>
      {places && search && (
        <div className={classes.root} >
          <Autocomplete
            options={options.sort((a, b) => -b.projectName.localeCompare(a.projectName))}
            groupBy={(option) => option.projectName}
            getOptionLabel={(option) => `${option.project.name} - ${option.registration || 'sem matrícula'}`}
            filterOptions={filterOptions}
            style={{ width: 290, backgroundColor: '#fff', borderRadius: 5, boxShadow: '0 1px 5px rgba(0,0,0,0.65)' }}
            classes={{ option: classes.option }}
            onChange={onChange}
            inputValue={inputValue}
            popupIcon={null}
            onInputChange={(event, value, reason) => {
              if (reason === 'input') {
                setInputChange(value)
              }
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  className={classes.select}
                  placeholder="Pesquisar imóvel"
                  color="secondary"
                  size="small"
                  variant="outlined"
                  autoFocus
                />
              )
            }}
            ListboxProps={
              {
                style:{
                    maxHeight: '230px',
                }
              }
            }
            noOptionsText="Nenhum imóvel encontrado"
            disableCloseOnSelect
            disableClearable
            autoHighlight
            open
          />
        </div>
      )}
    </Control>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none'
      },
    },
  },
  progress: {
    color: '#000'
  },
  option: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&[data-focus="true"]': {
      backgroundColor: '#fff',
      borderColor: 'transparent',
    },
    '&[aria-selected="true"]': {
      backgroundColor: '#ccc',
      borderColor: 'transparent',
    },
  },
}))
