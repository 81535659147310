import React from 'react'

import Control from 'react-leaflet-control'
import logo from '../assets/images/renobrax-geo.png'

const HomeLogo = ({ position }) => {
  return (
    <Control position={position}>
      <img src={logo} alt="Renobrax" style={{ width: 120, borderRadius: 5 }} />
    </Control>
  )
}

export default HomeLogo
