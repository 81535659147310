import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import ProjectExport from './project-export'
import ProjectExportKML from './project-export-kml'
import { PROJECTS } from '../graphql/project'

const Dashboard = ({ isOpen, close }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const { loading, error, data } = useQuery(PROJECTS)

  if (loading) {
    return null
  }

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="xl" fullWidth>
      <DialogTitle>Dashboard de Projetos</DialogTitle>
      <DialogContent>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell style={{width: 100}} align="center">Imóveis</TableCell>
              <TableCell style={{width: 90}} align="center">Contratadas, com GEO</TableCell>
              <TableCell style={{width: 90}} align="center">Contratadas, sem GEO</TableCell>
              <TableCell style={{width: 90}} align="center">Não Contratadas, com GEO</TableCell>
              <TableCell style={{width: 90}} align="center">Não Contratadas, sem GEO</TableCell>
              <TableCell style={{width: 90}} align="center">GEO Averbado</TableCell>
              <TableCell style={{width: 90}} align="center">Sem Pendências</TableCell>
              <TableCell style={{width: 90}} align="center">Impedidas</TableCell>
              <TableCell style={{width: 90}} align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.projects.map(project => {
              return (
                <TableRow key={project.id}>
                  <TableCell align="left">
                    <Typography noWrap>{project.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap>{project.placesCount}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap>{project.geoCount}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap>{project.contractCount}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap>{project.geo2Count}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap>{project.emptyCount}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap>{project.geoEndorsedCount}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap>{project.okCount}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap>{project.blockedCount}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <ProjectExport {...project} />
                      <ProjectExportKML {...project} />
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <br/>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={close}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed'
  },
}))

export default Dashboard
