import gql from 'graphql-tag'

export const ADD_PLACE = gql`
  mutation AddPlace($code: String!, $name: String!, $projectId: String!, $geometry: JSON!) {
    addPlace(code: $code, name: $name, projectId: $projectId, geometry: $geometry) {
      id
      type
      code
      name
      status
      blocked
      registration
      incra
      nirf
      car
      geometry
      project {
        name
        color
        lat
        lng
      }
      owners {
        documentId
        name
        rg
        email
      }
    }
  }
`

export const UPDATE_PLACE = gql`
  mutation UpdatePlace($id: String!, $name: String!, $projectId: String!, $blocked: Boolean, $registration: String, $incra: String, $nirf: String, $car: String, $notes: String, $address: AddressInput, $geometry: JSON) {
    updatePlace(id: $id, name: $name, projectId: $projectId, blocked: $blocked, registration: $registration, incra: $incra, nirf: $nirf, car: $car, notes: $notes, address: $address, geometry: $geometry) {
      id
      type
      code
      name
      status
      blocked
      readOnly
      registration
      incra
      nirf
      car
      notes
      geometry
      project {
        id
        name
        lat
        lng
        color
      }
      owners {
        id
        documentId
        name
        isCompany
        rg
        email
        phone
        occupation
        dateOfBirth
        registration
        notes
        address {
          cep
          street
          number
          complement
          neighborhood
          city
          state
        }
      }
      ownersHistory {
        id
        documentId
        name
        isCompany
        rg
        email
        phone
        occupation
        dateOfBirth
        registration
        notes
        address {
          cep
          street
          number
          complement
          neighborhood
          city
          state
        }
        deletedAt
      }
    }
  }
`

export const REMOVE_PLACE = gql`
  mutation RemovePlace($id: String!) {
    removePlace(id: $id)
  }
`

export const PLACES = gql`
  {
    places {
      id
      type
      code
      name
      status
      blocked
      registration
      incra
      nirf
      car
      geometry
      project {
        name
        color
        lat
        lng
      }
      owners {
        documentId
        name
        rg
        email
      }
    }
  }
`

export const PLACE = gql`
  query Place($id: String!) {
    place(id: $id) {
      id
      type
      code
      name
      status
      checklist {
        error,
        type,
        message,
      }
      blocked
      readOnly
      registration
      incra
      nirf
      car
      notes
      geometry
      address {
        cep
        street
        number
        complement
        neighborhood
        city
        state
      }
      project {
        id
        name
        lat
        lng
        color
      }
      owners {
        id
        documentId
        name
        isCompany
        rg
        email
        phone
        occupation
        dateOfBirth
        registration
        notes
        address {
          cep
          street
          number
          complement
          neighborhood
          city
          state
        }
      }
      ownersHistory {
        id
        documentId
        name
        isCompany
        rg
        email
        phone
        occupation
        dateOfBirth
        registration
        notes
        address {
          cep
          street
          number
          complement
          neighborhood
          city
          state
        }
        deletedAt
      }
    }
  }
`

export const DOWNLOAD = gql`
  query Download($projectId: String!) {
    download(projectId: $projectId) {
      id
      type
      code
      name
      status
      geometry
      checklist {
        message
      }
      blocked
      readOnly
      registration
      incra
      nirf
      car
      notes
      owners {
        name
        documentId
        phone
        address {
          cep
          street
          number
          complement
          neighborhood
          city
          state
        }
      }
    }
  }
`
