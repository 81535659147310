import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import logo from '../assets/images/renobrax.jpeg'

import PlaceStatus from './place-status'
import PlaceChecklist from './place-checklist'
import PlaceDetails from './place-details'
import PlaceOwners from './place-owners'
import PlaceDocuments from './place-documents'
import PlaceProjectUpdate from './place-project-update'
import PlaceGeometryUpdate from './place-geometry-update'

import { PLACE } from '../graphql/place'
import { AppContext } from '../contexts/app-context'

const Place = ({ id }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { isAdmin } = useContext(AppContext)
  const [expanded, setExpanded] = useState('checklist')

  const expand = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const { loading, error, data } = useQuery(PLACE, { variables: { id } })

  if (loading) {
    return (
      <LinearProgress color="secondary" />
    )
  }

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  const { place } = data

  return (
    <Container style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 20 }}>
      <img src={logo} alt="Renobrax" style={{ width: 180, marginLeft: -7, marginBottom: 5 }} />
      <Box display="flex" alignItems="center">
        <Typography variant="h6">
          {place.name}
        </Typography>
        {isAdmin && (<PlaceGeometryUpdate {...place} />)}
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1">
          {place.project.name}
        </Typography>
        {isAdmin && (<PlaceProjectUpdate {...place} />)}
      </Box>
      <PlaceStatus status={place.status} />
      <br />
      <div className={classes.root}>
        <ExpansionPanel expanded={expanded === 'checklist'} onChange={expand('checklist')}>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>Checklist do Imóvel</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <PlaceChecklist {...place} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'details'} onChange={expand('details')}>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>Dados do Imóvel</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <PlaceDetails {...place} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'owners'} onChange={expand('owners')}>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>Dados dos Proprietários</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <PlaceOwners {...place} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'documents'} onChange={expand('documents')}>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>Documentos Anexados</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <PlaceDocuments {...place} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </Container>
  )
}

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiExpansionPanelDetails)

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}))

export default Place