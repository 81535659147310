import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Link from '@material-ui/icons/Link'

import PlaceOwner from './place-owner'

const PlaceOwnersHistory = ({ history, placeId, isOpen, close }) => {
  const classes = useStyles()
  const [ownersHistory, setOwnersHistory] = useState([])
  const [owner, setOwner] = useState(null)
  const [isOpenView, setIsOpenView] = useState(false)

  useEffect(() => {
    if (history) {
      setOwnersHistory(history)
    }
  }, [history])

  const openView = (owner) => {
    setOwner(owner)
    setIsOpenView(true)
  }

  const closeView = () => {
    setIsOpenView(false)
  }

  return (
    <Dialog open={isOpen} onClose={close} maxWidth={'sm'} fullWidth>
      <DialogTitle>Histórico de Proprietários</DialogTitle>
      <DialogContent>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="center">Data da Exclusão</TableCell>
              <TableCell style={{width: 45}} align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ownersHistory.map(owner => {
              return (
                <TableRow key={owner.id}>
                  <TableCell align="left">
                    <Typography noWrap>{owner.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap>{owner.deletedAt}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Tooltip title="Visualizar" arrow>
                        <Fab size="small" color="secondary" onClick={() => openView(owner)}>
                          <Link />
                        </Fab>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
            <PlaceOwner isOpen={isOpenView} close={closeView} placeId={placeId} readOnly={true} owner={owner}  />
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={close}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed'
  },
}))

export default PlaceOwnersHistory
