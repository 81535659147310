import React, { Fragment, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AccountTree from '@material-ui/icons/AccountTree'

import ProjectSelect from './project-select'
import { UPDATE_PLACE } from '../graphql/place'

const PlaceProjectUpdate = ({ id, name, blocked, registration, incra, nirf, car, notes, project, geometry }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)
  const [projectId, setProjectId] = useState(project.id)
  const [updatePlace, { loading }] = useMutation(UPDATE_PLACE)

  const open = (e) => {
    setProjectId('select')
    setIsOpen(true)
  }

  const close = (e) => {
    setIsOpen(false)
  }

  const select = (e) => {
    setProjectId(e.target.value)
  }

  const submit = async (e) => {
    e.preventDefault()

    if (projectId ===  'select') {
      enqueueSnackbar(`Projeto selecionado é inválido.`, { variant: 'warning' })
      return
    }

    try {
      await updatePlace({
        variables: {
          id,
          name,
          projectId,
          blocked,
          registration,
          incra,
          nirf,
          car,
          notes,
          geometry
        }
      })
      enqueueSnackbar(`O imóvel foi atualizado com sucesso.`, { variant: 'success' })
      setIsOpen(false)
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <Fragment>
      <Tooltip title="Alterar projeto" placement="right" arrow>
        <IconButton size="small" color="secondary" onClick={open}>
          <AccountTree/>
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={() => close()} maxWidth={'xs'} fullWidth>
        <DialogTitle>Alterar Projeto</DialogTitle>
        <form onSubmit={submit}>
          <DialogContent>
            <ProjectSelect projectId={projectId} select={select} width={'100%'} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => close()} color="primary">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              Atualizar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  )
}

export default PlaceProjectUpdate
