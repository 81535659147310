import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Switch from '@material-ui/core/Switch'

import { MEMBERS, UPDATE_MEMBER } from '../graphql/member'

const ProjectMemberUpdate = ({ id, readOnly, project }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [ updateMember, { loading, error } ] = useMutation(UPDATE_MEMBER, {
    update() {
      enqueueSnackbar(`Membro atualizado com sucesso.`, { variant: 'success' })
    },
    refetchQueries: [{ query: MEMBERS, variables: { projectId: project.id } }]
  })

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  const update = async () => {
    try {
      await updateMember({
        variables: {
          id,
          readOnly: !readOnly
        }
      })
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <Switch
      checked={readOnly}
      onChange={update}
      disabled={loading}
    />
  )
}

export default ProjectMemberUpdate
