import React, { createContext, useState } from 'react'

export const PlacesContext = createContext()

const PlacesContextProvider = ({ children }) => {
  const [places, setPlaces] = useState(null)
  const [showDetails, setShowDetais] = useState(false)
  const [latlng, setLatlng] = useState([-20.5536554, -56.644367])
  const [zoom, setZoom] = useState(4)

  return (
    <PlacesContext.Provider value={{
      places,
      setPlaces,
      showDetails,
      setShowDetais,
      latlng,
      setLatlng,
      zoom,
      setZoom
    }}>
      {children}
    </PlacesContext.Provider>
  )
}

export default PlacesContextProvider
