import React, { Fragment, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { TwitterPicker } from 'react-color'
import { useSnackbar } from 'notistack'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Timeline from '@material-ui/icons/Timeline'

import { UPDATE_PROJECT } from '../graphql/project'

const ProjectPicker = ({ id, name, lat, lng, color }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [picker, setPicker] = useState(false)
  const [newColor, setNewColor] = useState(color)
  const [updateProject] = useMutation(UPDATE_PROJECT)

  const handleClick = (visible) => {
    setPicker(visible)
  }

  const handleClickAway = () => {
    setPicker(false)
  }

  const handleColorChange = async ({ hex }) => {
    setNewColor(hex)
    setPicker(false)

    try {
      await updateProject({
        variables: {
          id,
          name,
          lat,
          lng,
          color: hex
        }
      })
      enqueueSnackbar(`A cor do projeto foi alterada com sucesso.`, { variant: 'success' })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Tooltip title="Trocar a cor do projeto" placement="right" arrow>
          <IconButton size="small" onClick={() => handleClick(!picker)}>
            <Timeline style={{ color: newColor }} />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
      {picker && (
        <div style={{ position: 'absolute', zIndex: 2 }}>
          <TwitterPicker triangle="hide" onChangeComplete={handleColorChange} />
        </div>
      )}
    </Fragment>
  )
}

export default ProjectPicker
