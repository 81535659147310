import React, { useState } from 'react'

import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'

import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Save from '@material-ui/icons/Save'
import Link from '@material-ui/icons/Link'
import Delete from '@material-ui/icons/Delete'

// import MaskedInput from 'react-text-mask'
import NumberFormat from 'react-number-format'

import PlaceRemove from './place-remove'
import { UPDATE_PLACE } from '../graphql/place'

const PlaceDetails = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [name, setName] = useState(props.name)
  const [registration, setRegistration] = useState(props.registration || '')
  const [incra, setIncra] = useState(props.incra || '')
  const [nirf, setNirf] = useState(props.nirf || '')
  const [car, setCar] = useState(props.car || '')
  const [notes, setNotes] = useState(props.notes || '')
  const [blocked, setBlocked] = useState(props.blocked || false)
  const [openRemove, setOpenRemove] = useState(false)
  const [updatePlace, { loading }] = useMutation(UPDATE_PLACE)

  const submit = async (e) => {
    e.preventDefault()

    try {
      await updatePlace({
        variables: {
          id: props.id,
          name,
          projectId: props.project.id,
          blocked,
          registration,
          incra,
          nirf,
          car,
          notes,
          geometry: props.geometry
        }
      })
      enqueueSnackbar(`O imóvel foi atualizado com sucesso.`, { variant: 'success' })
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const remove = () => {
    setOpenRemove(true)
  }

  const close = () => {
    setOpenRemove(false)
  }

  const block = (e) => {
    setBlocked(e.target.checked)
  }

  return (
    <form className={classes.root} onSubmit={submit}>
      <TextField
        label="Nome do imóvel"
        variant="outlined"
        value={name}
        onChange={e => setName(e.target.value)}
        InputLabelProps={{ shrink: true }}
        fullWidth
        required
      />
      <TextField
        label="Matrícula"
        variant="outlined"
        value={registration}
        onChange={e => setRegistration(e.target.value)}
        style={{ width: '46%' }}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <TextField
        label="INCRA"
        variant="outlined"
        value={incra}
        onChange={e => setIncra(e.target.value)}
        style={{ width: '46%' }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: Incra,
          endAdornment: (
            <a
              tabIndex="-1"
              target="_blank"
              href={`https://sncr.serpro.gov.br/ccir/emissao?windowId=9bb&inputCodigoImovel=${incra}&inputCpf=`}
              rel="noopener noreferrer"
            >
              <InputAdornment position="end">
                <IconButton size="small" color="primary">
                  <Link />
                </IconButton>
              </InputAdornment>
            </a>
          ),
        }}
        fullWidth
      />
      <TextField
        label="ITR / NIRF"
        variant="outlined"
        value={nirf}
        onChange={e => setNirf(e.target.value)}
        style={{ width: '46%' }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: Nirf,
          endAdornment: (
            <a
              tabIndex="-1"
              target="_blank"
              href={`http://servicos.receita.fazenda.gov.br/Servicos/certidaoITR/Certidao/Emissao?NI=${nirf}`}
              rel="noopener noreferrer"
            >
              <InputAdornment position="end">
                <IconButton size="small" color="primary">
                  <Link />
                </IconButton>
              </InputAdornment>
            </a>
          ),
        }}
        fullWidth
      />
      <TextField
        label="CAR"
        variant="outlined"
        value={car}
        onChange={e => setCar(e.target.value)}
        style={{ width: '46%' }}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <TextField
        label="Observações"
        multiline
        rows="6"
        value={notes}
        onChange={e => setNotes(e.target.value)}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      {!props.readOnly && (
        <div style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <FormControlLabel
            control={
              <Switch
                tabIndex="-1"
                checked={blocked}
                onChange={block}
              />
            }
            label="Impedimento"
          />
          <div style={{ margin: 5 }}>
            <Fab type="submit" color="primary" disabled={loading}>
              {loading ? <CircularProgress color="secondary" /> : <Save/>}
            </Fab>
            &nbsp;&nbsp;
            <Tooltip title="Remover Imóvel" placement="bottom" arrow>
              <Fab color="secondary" onClick={() => remove()}>
                <Delete />
              </Fab>
            </Tooltip>
          </div>
        </div>
      )}
      <PlaceRemove id={props.id} isOpen={openRemove} close={close}/>
    </form>
  )
}

function Incra(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="###.###.###.###-#"
      isNumericString
    />
  )
}

function Nirf(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="#.###.###-#"
      isNumericString
    />
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}))

export default PlaceDetails