import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

import PlaceDocumentStatus from './place-document-status'
import { DOCUMENTS_HISTORY } from '../graphql/document'

const PlaceDocumentsHistory = ({ id, placeId, isOpen, close }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [documentsHistory, setDocumentsHistory] = useState([])

  const { loading, error, data } = useQuery(DOCUMENTS_HISTORY, {
    variables: {
      placeId,
      exited: true
    }
  })

  useEffect(() => {
    if (data && data.documentsHistory) {
      setDocumentsHistory(data.documentsHistory)
    }
  }, [data])

  if (loading) {
    return (
      <Dialog open={isOpen} onClose={close} fullWidth>
        <DialogTitle>Histórico de Documentos</DialogTitle>
        <DialogContent>
          <LinearProgress color="secondary" />
        </DialogContent>
      </Dialog>
    )
  }

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  return (
    <Dialog open={isOpen} onClose={close} maxWidth={'sm'} fullWidth>
      <DialogTitle>Histórico de Documentos</DialogTitle>
      <DialogContent>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Documento</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell style={{width: 160}} align="center">Data da Exclusão</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentsHistory.map(document => {
              return (
                <TableRow key={document.id}>
                  <TableCell align="left">
                    <a
                      target="_blank"
                      style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                      href={document.url}
                      rel="noopener noreferrer"
                    >
                      <Tooltip title={document.filename} placement={'left'} arrow>
                        <Typography noWrap>{document.filename}</Typography>
                      </Tooltip>
                    </a>
                  </TableCell>
                  <TableCell align="center">
                    <PlaceDocumentStatus type={document.type} status={document.status}/>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap>{document.deletedAt}</Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={close}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed'
  },
}))

export default PlaceDocumentsHistory
