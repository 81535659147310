import React, { Fragment, useContext, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Add from '@material-ui/icons/Add'
import Edit from '@material-ui/icons/Edit'
import Place from '@material-ui/icons/Place'
import MyLocation from '@material-ui/icons/MyLocation'
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'

import { AppContext } from '../contexts/app-context'

import ProjectAdd from './project-add'
import ProjectPicker from './project-picker'
import ProjectLayouts from './project-layouts'
import ProjectMembers from './project-members'
import { PROJECTS } from '../graphql/project'
import { PlacesContext } from '../contexts/places-context'

const Projects = ({ isOpen, close }) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()
  const { setLatlng, setZoom } = useContext(PlacesContext)
  const { isAdmin } = useContext(AppContext)

  const [project, setProject] = useState(null)
  const [isOpenProject, setIsOpenProject] = useState(false)
  const [isOpenLayouts, setIsOpenLayouts] = useState(false)
  const [isOpenUsers, setIsOpenUsers] = useState(false)

  const openProject = (project) => {
    setProject(project)
    setIsOpenProject(true)
  }

  const closeProject = () => {
    setIsOpenProject(false)
  }

  const openLayouts = (project) => {
    setProject(project)
    setIsOpenLayouts(true)
  }

  const closeLayouts = () => {
    setIsOpenLayouts(false)
  }

  const openUsers = (project) => {
    setProject(project)
    setIsOpenUsers(true)
  }

  const closeUsers = () => {
    setIsOpenUsers(false)
  }

  const openPlaces = (latlng) => {
    setZoom(12)
    setLatlng(latlng)
    close()
  }

  const { loading, error, data } = useQuery(PROJECTS)

  if (loading) {
    return null
  }

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Meus Projetos</DialogTitle>
      <DialogContent>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell style={{width: isAdmin ? 180 : 40}} align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.projects.map(project => {
              return (
                <TableRow key={project.id}>
                  <TableCell align="left">
                    <Typography noWrap>{project.name}</Typography>
                    {isAdmin && (<ProjectPicker {...project} />)}
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {isAdmin && (
                        <Fragment>
                          <Tooltip title="Editar" arrow>
                            <Fab size="small" color="secondary" onClick={() => openProject(project)}>
                              <Edit />
                            </Fab>
                          </Tooltip>
                          <Tooltip title="Layouts" arrow>
                            <Fab size="small" color="secondary" onClick={() => openLayouts(project)}>
                              <MyLocation />
                            </Fab>
                          </Tooltip>
                          <Tooltip title="Membros" arrow>
                            <Fab size="small" color="secondary" onClick={() => openUsers(project)}>
                              <SupervisorAccount />
                            </Fab>
                          </Tooltip>
                        </Fragment>
                      )}
                      <Tooltip title="Ver" arrow>
                        <Fab size="small" color="secondary" onClick={() => openPlaces([project.lat, project.lng])}>
                          <Place />
                        </Fab>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <br />
        {isAdmin && (
          <div>
            <Tooltip title="Adicionar Projeto" placement="bottom" arrow>
              <Fab color="primary" onClick={() => openProject(null)}>
                <Add />
              </Fab>
            </Tooltip>
          </div>
        )}
        <br/>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={close}>
          Fechar
        </Button>
      </DialogActions>
      <ProjectAdd isOpen={isOpenProject} close={closeProject} readOnly={true} project={project}  />
      {project && <ProjectLayouts isOpen={isOpenLayouts} close={closeLayouts} readOnly={true} project={project}  />}
      {project && <ProjectMembers isOpen={isOpenUsers} close={closeUsers} readOnly={true} project={project}  />}
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed'
  },
}))

export default Projects
