import React, { useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Search from '@material-ui/icons/Search'

import NumberFormat from 'react-number-format'

import { OWNER } from '../graphql/owner'
import PlaceOwner from './place-owner'

const PlaceOwnerSearch = ({ isOpen, close, placeId, readOnly  }) => {
  const { enqueueSnackbar } = useSnackbar()
  const inputText = useRef('')

  const [isCompany, setIsCompany] = useState(false)
  const [isOpenAdd, setIsOpenAdd] = useState(false)

  const [getOwner, { loading, error, data }] = useLazyQuery(OWNER, {
    variables: {
      documentId: inputText.current.value ? inputText.current.value.replace(/\D/g,'') : ''
    }
  })

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  const onClose = () => {
    if (!loading) {
      setIsOpenAdd(false)
      setIsCompany(false)
      close()
    }
  }

  const openAdd = () => {
    getOwner()
    setIsOpenAdd(true)
    // onClose()
  }

  const closeAdd = () => {
    setIsOpenAdd(false)
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        Adicionar Proprietário
      </DialogTitle>
      <form onSubmit={e => {
        e.preventDefault()
        openAdd()
      }}>
        <DialogContent>
          <DialogContentText>
            Antes de adicionar, verifique se o proprietário já foi cadastrado.
          </DialogContentText>
          <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1, alignItems: 'center', paddingTop: 10 }}>
            <TextField
              label={isCompany ? 'CNPJ' : 'CPF'}
              variant="outlined"
              style={{ width: '47%' }}
              // value={documentId}
              inputRef={inputText}
              // onChange={e => setDocumentId(e.target.value)}
              InputProps={{ inputComponent: isCompany ? Cnpj : Cpf }}
              InputLabelProps={{ shrink: true }}
              required
              autoFocus
            />
            <Fab color="primary" onClick={openAdd} disabled={loading}>
              {loading ? <CircularProgress color="secondary" /> : <Search/>}
            </Fab>
            <FormControlLabel
              label="Pessoa Jurídica"
              control={
                <Switch
                  tabIndex="-1"
                  disabled={readOnly}
                  checked={isCompany}
                  onChange={e => setIsCompany(e.target.checked)}
                />
              }
            />
          </div>
          <br/>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </form>
      {isOpenAdd && !loading && <PlaceOwner isOpen={isOpenAdd} close={closeAdd} placeId={placeId} readOnly={readOnly} owner={data ? data.owner : null} search={{ documentId: inputText.current.value.replace(/\D/g,''), isCompany }} />}
    </Dialog>
  )
}

function Cpf(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="###.###.###-##"
      isNumericString
    />
  )
}

function Cnpj(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="##.###.###/####-##"
      isNumericString
    />
  )
}

export default PlaceOwnerSearch
