import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import Delete from '@material-ui/icons/Delete'

import { MEMBERS, REMOVE_MEMBER } from '../graphql/member'

const ProjectMemberRemove = ({ id, project }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [ removeMember, { loading, error } ] = useMutation(REMOVE_MEMBER, {
    update() {
      enqueueSnackbar(`Membro removido com sucesso.`, { variant: 'success' })
    },
    refetchQueries: [{ query: MEMBERS, variables: { projectId: project.id } }]
  })

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  const remove = async () => {
    try {
      await removeMember({
        variables: {
          id,
          projectId: project.id
        }
      })
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <Tooltip title="Remover" arrow>
      <Fab size="small" color="secondary" onClick={remove} disabled={loading}>
        {loading ? <CircularProgress color="secondary" /> : <Delete/>}
      </Fab>
    </Tooltip>
  )
}

export default ProjectMemberRemove
