import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { AppContext } from '../contexts/app-context'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { token } = useContext(AppContext)

  return (
    <Route
      {...rest}
      render={() => token
        ? <Component {...rest} />
        : <Redirect to="/sign-in" />
      }
    />
  )
}

export default PrivateRoute