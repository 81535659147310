import React, { Fragment, useState, useContext, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import hash from 'object-hash'

import LinearProgress from '@material-ui/core/LinearProgress'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { makeStyles } from '@material-ui/core/styles'
import { GeoJSON } from 'react-leaflet'

import ProjectsLayouts from './projects-layouts'
import Place from './place'
import { AppContext } from '../contexts/app-context'
import { PlacesContext } from '../contexts/places-context'
import { PLACES } from '../graphql/place'

const Places = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [place, setPlace] = useState()

  const { isAdmin, filters } = useContext(AppContext)
  const { places, setPlaces, showDetails, setShowDetais } = useContext(PlacesContext)
  const { loading, error, data } = useQuery(PLACES)

  useEffect(() => {
    if (!loading && data) {
      setPlaces({
        type: 'FeatureCollection',
        features: data.places.filter(place => filters.includes(place.status))
      })
    }
  }, [loading, filters, data, setPlaces])

  if (loading) {
    return (
      <LinearProgress color="primary" />
    )
  }

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  const onEachFeature = (feature, layer) => {
    try {
      let color
      switch (feature.status) {
        case 'geo':
          color = 'blue'
          break
        case 'geo2':
          color = 'purple'
          break
        case 'contract':
          color = 'gold'
          break
        case 'blocked':
          color = '#5d4037'
          break
        case 'ok':
          color = 'green'
          break
        default:
          color = 'red'
          break
      }

      const tooltip = `${feature.project.name} - ${feature.registration || 'sem matrícula'}`

      layer.bindTooltip(tooltip, { permanent: false })

      layer.setStyle({
        weight: 1.5,
        fillColor: color,
        color: feature.project.color,
        fillOpacity: feature.status === 'blocked' ? 0.8 : 0.3
      })

      layer.on('click', async (e) => {
        setPlace(feature)
        setShowDetais(true)
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Fragment>
      <GeoJSON key={hash(places)} data={places} onEachFeature={onEachFeature} />
      <SwipeableDrawer
        anchor="right"
        open={showDetails}
        onOpen={() => setShowDetais(false)}
        onClose={() => setShowDetais(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {place && <Place {...place} />}
      </SwipeableDrawer>
      {isAdmin && <ProjectsLayouts />}
    </Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: 530,
      flexShrink: 0,
    },
  },
}))

export default Places
