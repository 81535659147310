import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import AttachFile from '@material-ui/icons/AttachFile'
import Delete from '@material-ui/icons/Delete'
import Restore from '@material-ui/icons/Restore'
import Edit from '@material-ui/icons/Edit'
import Link from '@material-ui/icons/Link'
import { makeStyles } from '@material-ui/core/styles'

import { DOCUMENTS } from '../graphql/document'

import PlaceDocument from './place-document'
import PlaceDocumentStatus from './place-document-status'
import PlaceDocumentsHistory from './place-documents-history'
import PlaceDocumentRemove from './place-document-remove'

const PlaceDocuments = (props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [document, setDocument] = useState(null)
  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [isOpenHistory, setIsOpenHistory] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [isOpenRemove, setIsOpenRemove] = useState(false)

  const { loading, error, data } = useQuery(DOCUMENTS, {
    variables: {
      placeId: props.id
    }
  })

  if (loading) {
    return (
      <CircularProgress color="secondary" />
    )
  }

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  const openAdd = () => {
    setIsOpenAdd(true)
  }

  const closeAdd = () => {
    setIsOpenAdd(false)
  }

  const openHistory = (owner) => {
    setIsOpenHistory(true)
  }

  const closeHistory = () => {
    setIsOpenHistory(false)
  }

  const openEdit = (document) => {
    setDocument(document)
    setIsOpenEdit(true)
  }

  const closeEdit = () => {
    setIsOpenEdit(false)
  }

  const openRemove = (document) => {
    setDocument(document)
    setIsOpenRemove(true)
  }

  const closeRemove = () => {
    setIsOpenRemove(false)
  }

  return (
    <div>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Documento</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell style={{ width: props.readOnly ? 45 : 85 }} align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.documents.map(document => {
              return (
                <TableRow key={document.id}>
                  <TableCell align="left">
                    <a
                      target="_blank"
                      style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                      href={document.url}
                      rel="noopener noreferrer"
                    >
                      <Tooltip title={document.filename} placement={'left'} arrow>
                        <Typography noWrap>{document.filename}</Typography>
                      </Tooltip>
                    </a>
                  </TableCell>
                  <TableCell className={classes.actions} align="center">
                    <PlaceDocumentStatus type={document.type} status={document.status} />
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Tooltip title={props.readOnly ? 'Visualizar' : 'Editar'} arrow>
                        <Fab size="small" color="secondary" onClick={() => openEdit(document)}>
                          {props.readOnly ? <Link/> : <Edit />}
                        </Fab>
                      </Tooltip>
                      {!props.readOnly && (
                        <Tooltip title="Remover" arrow>
                          <Fab size="small" color="secondary" onClick={() => openRemove(document)}>
                            <Delete />
                          </Fab>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <br/>
      {!props.readOnly && (
        <div>
          <Tooltip title="Adicionar Documento" placement="bottom" arrow>
            <Fab color="primary" onClick={openAdd}>
              <AttachFile />
            </Fab>
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip title="Histórico de Documentos" placement="bottom" arrow>
            <Fab color="secondary" onClick={openHistory}>
              <Restore />
            </Fab>
          </Tooltip>
        </div>
      )}
      <PlaceDocument isOpen={isOpenAdd} close={closeAdd} placeId={props.id} />
      <PlaceDocumentsHistory placeId={props.id} isOpen={isOpenHistory} close={closeHistory} />
      {document && isOpenEdit && <PlaceDocument isOpen={isOpenEdit} close={closeEdit} placeId={props.id} document={document} />}
      {document  && isOpenRemove && <PlaceDocumentRemove id={document.id} placeId={props.id} isOpen={isOpenRemove} close={closeRemove} />}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed'
  },
}))

export default PlaceDocuments