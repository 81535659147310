import gql from 'graphql-tag'

export const ADD_PROJECT = gql`
  mutation AddProject($name: String!, $lat: Float!, $lng: Float!) {
    addProject(name: $name, lat: $lat, lng: $lng) {
      id
      name
      lat
      lng
      color
      placesCount
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: String!, $name: String!, $lat: Float, $lng: Float, $color: String) {
    updateProject(id: $id, name: $name, lat: $lat, lng: $lng, color: $color) {
      id
      name
      lat
      lng
      color
      placesCount
    }
  }
`

export const PROJECTS = gql`
  {
    projects {
      id
      name
      lat
      lng
      color
      placesCount
      geoCount
      geo2Count
      geoEndorsedCount
      contractCount
      emptyCount
      blockedCount
      okCount
    }
  }
`

export const PROJECT = gql`
  query Project($id: String!) {
    project(id: $id) {
      id
      name
      lat
      lng
      color
      placesCount
      geoCount
      geo2Count
      geoEndorsed
      contractCount
      emptyCount
      blockedCount
      okCount
    }
  }
`
