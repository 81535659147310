import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

import { FORGOT } from '../graphql/user'

const Forgot = ({ isOpen, close }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { enqueueSnackbar } = useSnackbar()

  const [ email, setEmail ] = useState('')
  const [ forgot, { loading }] = useMutation(FORGOT, {
    update() {
      enqueueSnackbar(`Verifique sua caixa de e-mails para recuperar sua conta.`, { variant: 'success' })
      close()
    }
  })

  async function onSubmit(event) {
    event.preventDefault()

    if (!executeRecaptcha) {
      enqueueSnackbar('Você é um robô? 🤔', { variant: 'error' })
      return
    }

    const recaptchaToken = await executeRecaptcha('forgot')
    if (!recaptchaToken) {
      enqueueSnackbar('Você é um robô? 🤔', { variant: 'error' })
      return
    }

    try {
      await forgot({ variables: { recaptchaToken, email }})
    } catch (error) {
      console.log(error)
      const message = error.message.replace('GraphQL error: ', '')
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  function onClose() {
    if (!loading) {
      close()
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle id="form-dialog-title">Recuperar minha conta</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText>
            Informe o seu e-mail para recuperar sua conta.
          </DialogContentText>
          <TextField margin="dense" id="name" label="E-mail" type="email" value={email} onChange={event => setEmail(event.target.value)} fullWidth autoFocus required />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            Recuperar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default Forgot
