import gql from 'graphql-tag'

export const SIGN_IN = gql`
  mutation SignIn($recaptchaToken: String!, $email: String!, $password: String!) {
    signIn(recaptchaToken: $recaptchaToken, email: $email, password: $password)
  }
`

export const FORGOT = gql`
  mutation Forgot($recaptchaToken: String!, $email: String!) {
    forgot(recaptchaToken: $recaptchaToken, email: $email)
  }
`

export const RESET = gql`
  mutation Reset($token: String!, $newPassword: String!, $repeatNewPassword: String!) {
    reset(token: $token, newPassword: $newPassword, repeatNewPassword: $repeatNewPassword)
  }
`

export const USERS = gql`
  {
    users {
      id
      name
      email
      status
    }
  }
`

export const ADD_USER = gql`
  mutation AddUser($name: String!, $email: String!) {
    addUser(name: $name, email: $email) {
      id
    }
  }
`

export const REMOVE_USER = gql`
  mutation RemoveUser($id: String!) {
    removeUser(id: $id)
  }
`