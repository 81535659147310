import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

import { PROJECTS } from '../graphql/project'

const ProjectSelect = ({ projectId, width, select }) => {
  const { loading, error, data } = useQuery(PROJECTS)

  if (error) {
    console.log(error)
  }

  return (
    <FormControl variant="outlined" style={{ width }} required>
      <InputLabel>Projeto</InputLabel>
      <Select
        value={projectId || 'select'}
        onChange={select}
        label="Projeto"
      >
        <MenuItem value="select">{loading ? 'Carregando projetos...' : 'Clique para selecionar o projeto'}</MenuItem>
        {data && data.projects.map(project => (
          <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ProjectSelect
