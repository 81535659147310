import React, { Fragment } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import format from 'date-fns/format'

import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import Description from '@material-ui/icons/Description'

import { DOWNLOAD } from '../graphql/place'

import ReactExport from 'react-data-export'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ProjectExport = ({ id, name, placesCount, geoCount, geo2Count, geoEndorsedCount, contractCount, blockedCount, emptyCount, okCount }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [download, { loading, error, data }] = useLazyQuery(DOWNLOAD)

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  let places = []
  try {
    if (data && data.download) {
      for (const place of data.download.filter(x => x.status !== 'blocked')) {
        const messages = place.checklist
          .map(({ message }) => `${[message].join(' | ')}`)
          .join(' | ')
        place.messages = messages

        const ownerNames = place.owners && place.owners
          .map(({ name }) => `${[name].join(' | ')}`)
          .join(' | ')

        place.ownerNames = ownerNames

        const ownerDocuments = place.owners && place.owners
          .map(({ documentId }) => `${[documentId].join(' | ')}`)
          .join(' | ')

        place.ownerDocuments = ownerDocuments

        const ownerAddresses = place.owners && place.owners
          .map(({ address: { cep, street, number, complement, neighborhood, city, state }}) => {
            let address = ''
            if (street) {
              address += `${street}`
            }
            if (number) {
              address += `, ${number}`
            }
            if (complement) {
              address += `, ${complement}`
            }
            if (street) {
              address += `, ${neighborhood}`
            }
            if (street) {
              address += `, ${cep}`
            }
            if (street) {
              address += `, ${city}`
            }
            if (street) {
              address += `, ${state}`
            }
            return address
          })
          .join(' | ')

        place.ownerAddresses = ownerAddresses

        switch (place.status) {
          case 'geo':
            place.status = 'Contratada, com GEO'
            break
          case 'contract':
            place.status = 'Contratada, sem GEO'
            break
          case 'geo2':
            place.status = 'Não contratada, com GEO'
            break
          case 'blocked':
            place.status = 'Impedida'
            break
          case 'empty':
            place.status = 'Não contratada, sem GEO'
            break
          default:
            place.status = 'Área sem pendências'
        }

        places.push(place)
      }
    }
  } catch (e) {
    console.log(e)
    enqueueSnackbar(e.message, { variant: 'error' })
  }

  const start = (e) => {
    e.preventDefault()
    download({ variables: { projectId: id } })
  }

  return (
    <Fragment>
      <Tooltip title="Download Excel" placement="bottom" arrow>
        <Fab size="small" color="secondary" onClick={start}>
          {loading ? <CircularProgress color="inherit" /> : <Description />}
        </Fab>
      </Tooltip>
      {places.length > 0 && (
        <ExcelFile filename={`${name} - ${format(new Date(), 'dd-MM-yyyy')}`} hideElement>
          <ExcelSheet data={places} name="Propriedades">
            <ExcelColumn label="Matrícula" value="registration"/>
            <ExcelColumn label="Proprietários" value="ownerNames"/>
            <ExcelColumn label="CPF/CNPJ" value="ownerDocuments"/>
            <ExcelColumn label="Endereços" value="ownerAddresses"/>
            <ExcelColumn label="INCRA" value="incra"/>
            <ExcelColumn label="ITR" value="nirf"/>
            <ExcelColumn label="CAR" value="car"/>
            <ExcelColumn label="Observações" value="notes"/>
            <ExcelColumn label="Checklist" value="messages"/>
            <ExcelColumn label="Status" value="status"/>
          </ExcelSheet>
          <ExcelSheet data={[{ placesCount, geoCount, geo2Count, geoEndorsedCount, contractCount, blockedCount, emptyCount, okCount }]} name="Estatísticas">
            <ExcelColumn label="Imóveis" value="placesCount"/>
            <ExcelColumn label="Contratadas, com GEO" value="geoCount"/>
            <ExcelColumn label="Contratadas, sem GEO" value="contractCount"/>
            <ExcelColumn label="Não Contratadas, com GEO" value="geo2Count"/>
            <ExcelColumn label="Não Contratadas, sem GEO" value="emptyCount"/>
            <ExcelColumn label="GEO Averbado" value="geoEndorsedCount"/>
            <ExcelColumn label="Sem Pendência" value="okCount"/>
            <ExcelColumn label="Impedidas" value="blockedCount"/>
          </ExcelSheet>
        </ExcelFile>
      )}
    </Fragment>
  )
}

export default ProjectExport
