import React, { useState, useContext } from 'react'
import Chip from '@material-ui/core/Chip'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import LocationOn from '@material-ui/icons/LocationOn'
import Error from '@material-ui/icons/Error'
import Description from '@material-ui/icons/Description'
import CheckCircle from '@material-ui/icons/CheckCircle'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'

import { AppContext } from '../contexts/app-context'

const PlaceStatus = ({ status, size, hasClick }) => {
  const [clicked, setClicked] = useState(false)
  const { filters, setFilters } = useContext(AppContext)

  let color, backgroundColor, label, icon
  switch (status) {
    case 'geo':
      color = 'primary'
      backgroundColor = 'blue'
      label = 'Contratada, com GEO'
      icon = <LocationOn />
      break
    case 'contract':
      color = 'secondary'
      backgroundColor = '#DAA520'
      label = 'Contratada, sem GEO'
      icon = <Description />
      break
    case 'geo2':
      color = 'primary'
      backgroundColor = 'purple'
      label = 'Não contratada, com GEO'
      icon = <InsertDriveFile />
      break
    case 'blocked':
      color = 'primary'
      backgroundColor = '#5d4037'
      label = 'Impedida'
      icon = <RemoveCircle />
      break
    case 'empty':
      color = 'primary'
      backgroundColor = 'red'
      label = 'Não contratada, sem GEO'
      icon = <Error />
      break
    default:
      color = 'primary'
      backgroundColor = 'green'
      label = 'Área sem pendências'
      icon = <CheckCircle />
  }

  function handleStatus() {
    let selected = [...filters]
    if (filters.includes(status)) {
      selected = filters.filter(filter => filter !== status)
    } else {
      selected.push(status)
    }
    setFilters(selected)
    setClicked(!clicked)
  }

  return (
    <Chip
      icon={icon || null}
      size={size || 'medium'}
      color={color}
      label={label}
      onClick={hasClick ? handleStatus : null}
      style={{
        backgroundColor,
        letterSpacing: 1,
        fontSize: 15,
        opacity: clicked ? 0.5 : 1
      }}
    />
  )
}

export default PlaceStatus