import React from 'react'
import Chip from '@material-ui/core/Chip'

const PlaceDocumentStatus = ({ type, status, size }) => {
  let label

  switch (type) {
    case 'geo':
      label = 'GEO'
      break
    case 'contract':
      label = 'Contrato'
      break
    case 'registration':
      label = 'Matrícula'
      break
    case 'car':
      label = 'CAR'
      break
    case 'ccir':
      label = 'CCIR'
      break
    case 'itr':
      label = 'ITR'
      break
    default:
      label = 'Outro'
  }

  return (
    <Chip
      size={size || 'medium'}
      color={'primary'}
      label={label}
      style={{ letterSpacing: 1 }}
    />
  )
}

export default PlaceDocumentStatus