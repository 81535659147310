import React, { Fragment, useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import format from 'date-fns/format'
import tokml from 'tokml'
import { saveAs } from 'file-saver'

import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import Map from '@material-ui/icons/Map'

import { DOWNLOAD } from '../graphql/place'

function getColor(status) {
  switch (status) {
    case 'geo':
      return '0000ff'
    case 'contract':
      return 'DAA520'
    case 'geo2':
      return 'cc0099'
    case 'blocked':
      return '5d4037'
    case 'empty':
      return 'ff0000'
    default:
      return '33cc33'
  }
}

function getStatus(status) {
  switch (status) {
    case 'geo':
      return 'Contratada, com GEO'
    case 'contract':
      return 'Contratada, sem GEO'
    case 'geo2':
      return 'Não contratada, com GEO'
    case 'blocked':
      return 'Impedida'
    case 'empty':
      return 'Não contratada, sem GEO'
    default:
      return 'Área sem pendências'
  }
}

const ProjectExportKML = ({ id, name }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [download, { loading, error, data }] = useLazyQuery(DOWNLOAD)

  useEffect(() => {
    let features = []
    try {
      if (data && data.download) {
        for (const { code, name, registration, status, checklist, owners, geometry } of data.download) {
          features.push({
            type: 'Feature',
            properties: {
              code,
              name,
              registration,
              status: getStatus(status),
              checklist: checklist.map(({ message }) => `${[message].join(' ')}`).join(' '),
              owners: owners && owners.map(({ name }) => `${[name].join(', ')}`).join(', '),
              fill: getColor(status),
              'fill-opacity': 0.5
            },
            geometry
          })
        }

        const geojsonObject = {
          type: 'FeatureCollection',
          features
        }

        const kmlDocument = tokml(geojsonObject, {
          documentName: name,
          simplestyle: true
        })

        saveAs(new Blob([kmlDocument], {
          type: 'application/vnd.google-earth.kml+xml'
        }), `${name} - ${format(new Date(), 'dd-MM-yyyy')}.kml`)
      }
    } catch (e) {
      console.log(e)
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }, [data, name, enqueueSnackbar])

  if (error) {
    console.log(error)
    const message = error.message.replace('GraphQL error: ', '')
    enqueueSnackbar(message, { variant: 'error' })
    return null
  }

  const start = (e) => {
    e.preventDefault()
    download({ variables: { projectId: id } })
  }

  return (
    <Fragment>
      <Tooltip title="Download KML" placement="bottom" arrow>
        <Fab size="small" color="secondary" onClick={start}>
          {loading ? <CircularProgress color="inherit" /> : <Map />}
        </Fab>
      </Tooltip>
    </Fragment>
  )
}

export default ProjectExportKML
